import { Injectable } from '@angular/core';
import { ServicesService } from './services.service';
import { UserService } from './user.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {

  public rankingGeneral: any[];
  public rankingState: any[];
  public rankingSchool: any[];
  public rankingFriends: any[];
  private userInfo: any;
  private userId: string;
  public userLeaderboard: any = {};

  constructor(private services: ServicesService, private user: UserService, private util: UtilService) {
  }

  clearService() {
    this.rankingGeneral = [];
    this.rankingState = [];
    this.rankingSchool = [];
    this.rankingFriends = [];
  }

  public get(type) {
    switch (type) {
      case "Geral":
        return this.rankingGeneral;
      case "Estado":
        return this.rankingState;
      case "Escola":
        return this.rankingSchool;
      case "Amigos":
        return this.rankingFriends;
    }
  }

  public getUserRanking(type) {
    switch (type) {
      case "Geral":
        return this.userLeaderboard.general;
      case "Estado":
        return this.userLeaderboard.state;
      case "Escola":
        return this.userLeaderboard.school;
      case "Amigos":
        return this.userLeaderboard.friends;
    }
  }

  public loadRanking(type, offset = 0) {
    console.log("loadingleaderboards-type", type);
    this.userInfo = this.user.getUserInfo();
    this.userId = this.userInfo.id;
    let data = { limit: 50, offset, userId: this.userId };
    switch (type) {
      case "Geral":
        if (this.rankingGeneral && this.rankingGeneral.length)
          return Promise.resolve(this.rankingGeneral);
        return this.services.get("analytics/ranking", { data }).then(res => {
          this.rankingGeneral = res.data.leaderboard;
          this.rankingGeneral.forEach(player => this.treatAvatar(player));
          this.userLeaderboard.general = this.loadUserRanking(res.data);
          return this.rankingGeneral;
        });
      case "Estado":
        if (this.rankingState && this.rankingState.length)
          return Promise.resolve(this.rankingState);
        if (this.userInfo.profile.state && this.userInfo.profile.state !== 'none') {
          return this.services.get(`analytics/ranking/states/${this.userInfo.profile.state}`, { data }).then(res => {
            this.rankingState = res.data.leaderboard;
            this.rankingState.forEach(player => this.treatAvatar(player));
            this.userLeaderboard.state = this.loadUserRanking(res.data);
            return this.rankingState;
          });
        } else return Promise.reject("Cant request user's state leaderboards if user is not part of any state");
      case "Escola":
        if (!this.userInfo.school || !this.userInfo.school.id)
          return Promise.reject("Cant request user's school leaderboards if user is not part of any school");
        if (this.rankingSchool && this.rankingSchool.length)
          return Promise.resolve(this.rankingSchool);
        return this.services.get(`analytics/ranking/schools/${this.userInfo.school.id}`, { data }).then(res => {
          this.rankingSchool = res.data.leaderboard;
          this.rankingSchool.forEach(player => this.treatAvatar(player));
          this.userLeaderboard.school = this.loadUserRanking(res.data);
          return this.rankingSchool;
        });
      case "Amigos":
        if (this.rankingFriends && this.rankingFriends.length)
          return Promise.resolve(this.rankingFriends);
        if (!this.user.facebookFriendsInfo || !this.user.facebookFriendsInfo.length)
          return Promise.reject("Can't request user's friends leaderboards if user has no friends " +
            "(YOU ARE NOT FOREVER ALONE! PLEASE SEND ME A FRIEND REQUEST — san.monico :D )");
        return this.services.get("analytics/ranking/friends", { data }).then(res => {
          this.rankingFriends = res.data.leaderboard;
          this.rankingFriends.forEach(player => this.treatAvatar(player));
          this.userLeaderboard.friends = this.loadUserRanking(res.data);
          return this.rankingFriends;
        });
      default:
        return Promise.reject("Invalid type");
    }
  }

  private treatAvatar(player) {
    return player.avatar = this.util.getAvatar(player.avatar, player.facebookId);
  }

  loadUserRanking(leaderboard) {
    return {
      level: leaderboard.level,
      position: leaderboard.position,
      score: leaderboard.score
    }
  }
}
