import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'forgot-password',
    pathMatch: 'full',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  { path: '', redirectTo: 'splash-screen', pathMatch: 'full' },
  {
    path: 'tabs',
    children: [{
      path: '',
      loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    }, {
      path: 'delete',
      loadChildren: () => import('./modals/delete-account-popover/delete-account.module').then(m => m.DeleteAccountPageModule)
    }]
  },
  {
    path: 'challenge-detail',
    loadChildren: () => import('./challenges/challenge-detail/challenge-detail.module').then(m => m.ChallengeDetailPageModule)
  },
  {
    path: 'challenges',
    loadChildren: () => import('./challenges/challenges/challenges.module').then(m => m.ChallengesPageModule)
  },
  // {
  //   path: 'daily-objectives',
  //   loadChildren: () => import('./daily-objectives/daily-objectives.module').then(m => m.DailyObjectivesPageModule)
  // },
  {
    path: 'friends',
    loadChildren: () => import('./friends/friends.module').then(m => m.FriendsPageModule)
  },
  {
    path: 'game-history-questions',
    loadChildren: () => import('./game-history-questions/game-history-questions.module').then(m => m.GameHistoryQuestionsPageModule)
  },
  {
    path: 'game-mode',
    loadChildren: () => import('./game-mode/game-mode.module').then(m => m.GameModePageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./misc/maintenance/maintenance.module').then(m => m.MaintenancePageModule)
  },
  {
    path: 'pravaler',
    loadChildren: () => import('./misc/pravaler/pravaler.module').then(m => m.PravalerPageModule)
  },
  {
    path: 'update',
    loadChildren: () => import('./misc/update/update.module').then(m => m.UpdatePageModule)
  },
  {
    path: 'profile-compare',
    loadChildren: () => import('./profile-compare/profile-compare.module').then(m => m.ProfileComparePageModule)
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./profile-edit/profile-edit.module').then(m => m.ProfileEditPageModule)
  },
  {
    path: 'death-reward',
    loadChildren: () => import('./single-player/death-reward/death-reward.module').then(m => m.DeathRewardPageModule)
  },
  {
    path: 'no-user-match',
    loadChildren: () => import('./single-player/no-user-match/no-user-match.module').then(m => m.NoUserMatchPageModule)
  },
  {
    path: 'single-player-game',
    loadChildren: () => import('./single-player/single-player-game/single-player-game.module').then(m => m.SinglePlayerGamePageModule)
  },
  {
    path: 'single-player-game-results',
    loadChildren: () => import('./single-player/single-player-game-results/single-player-game-results.module')
      .then(m => m.SinglePlayerGameResultsPageModule)
  },
  {
    path: 'single-player-interval',
    loadChildren: () => import('./single-player/single-player-interval/single-player-interval.module')
      .then(m => m.SinglePlayerIntervalPageModule)
  },
  {
    path: 'single-player-lobby',
    loadChildren: () => import('./single-player/single-player-lobby/single-player-lobby.module').then(m => m.SinglePlayerLobbyPageModule)
  },
  {
    path: 'study',
    loadChildren: () => import('./study/study/study.module').then(m => m.StudyPageModule)
  },
  {
    path: 'study-detail',
    loadChildren: () => import('./study/study-detail/study-detail.module').then(m => m.StudyDetailPageModule)
  },
  {
    path: 'create-profile',
    loadChildren: () => import('./welcome/create-profile/create-profile.module').then(m => m.CreateProfilePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./welcome/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./welcome/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'splash-screen',
    loadChildren: () => import('./splash-screen/splash-screen.module').then(m => m.SplashScreenPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'daily-challenge',
    loadChildren: () => import('./daily-challenge/daily-challenge.module').then(m => m.DailyChallengePageModule)
  },
  {
    path: 'tourney',
    loadChildren: () => import('./tourney/tourney.module').then(m => m.TourneyPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
