import { Component, OnInit } from '@angular/core';
import {
  AlertController, LoadingController, ModalController, NavController, NavParams, Platform, PopoverController, ToastController
} from '@ionic/angular';
import { FullModalPage } from 'src/app/modals/full-modal/full-modal';
import { AudioService } from 'src/app/services/audio.service';
// import { ObjectivesService } from 'src/app/services/objectives.service';
import { ServicesService } from 'src/app/services/services.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';
import { BasePage } from '../../base/base.page';

interface listRankType {
  id: number;
  position: number;
  avatar: string;
  name: string;
  level: number;
  score: number;
  me: boolean;
}

@Component({
  selector: 'page-ranking-popover',
  templateUrl: './ranking-popover.html',
  styleUrls: ['./ranking-popover.scss'],
})
export class RankingPopoverPage extends BasePage implements OnInit {
  public rankingDetail = 'summary';
  public challenge: any;
  public data: any;
  public ranking: any[];
  public myRanking: any;
  public registered = true;
  public isObjective = false;
  public activeChallenge;
  public avatarImg = '../../../assets/imgs/avatar.jpg';
  public unisalesCoursesList = [
    { name: 'Administração', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 18.792,00' },
    { name: 'Administração', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 6.960, 00' },
    { name: 'Arquitetura e Urbanismo', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 38.610, 00' },
    { name: 'Arquitetura e Urbanismo', mode: 'MOB Semipresencial', periods: 10, scholarshipLimit: 'R$ 14.700, 00' },
    { name: 'Biomedicina', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 21.912, 00' },
    { name: 'Biomedicina', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 12.480, 00' },
    { name: 'Ciências Biológicas - Bacharelado', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 21.288, 00' },
    { name: 'Ciências Biológicas Bach', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 9.360, 00' },
    { name: 'Ciências Contábeis', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 19.152, 00' },
    { name: 'Ciências Contábeis', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 6.960, 00' },
    { name: 'Comunicação Social - Jornalismo', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 19.680, 00' },
    { name: 'Comunicação Social - Jornalismo', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 9.360, 00' },
    { name: 'Comunicação Social - Pub.e Propaganda', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 19.680, 00' },
    { name: 'Comunicação Social - Pub.e Propaganda', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 9.360, 00' },
    { name: 'CST - Analise e Des.de Sistemas', mode: 'Presencial', periods: 5, scholarshipLimit: 'R$ 11.875, 50' },
    { name: 'CST - Analise e Des.de Sistemas', mode: 'MOB Semipresencial', periods: 5, scholarshipLimit: 'R$ 5.850, 00' },
    { name: 'CST - BI(Business Intelligence)', mode: 'MOB Semipresencial', periods: 4, scholarshipLimit: 'R$ 4.680, 00' },
    { name: 'CST - Gestão de Marketing', mode: 'MOB Semipresencial', periods: 4, scholarshipLimit: 'R$ 3.480, 00' },
    { name: 'CST - Gestão de Recursos Humanos', mode: 'MOB Semipresencial', periods: 4, scholarshipLimit: 'R$ 3.480, 00' },
    { name: 'CST - Gestão Financeira', mode: 'MOB Semipresencial', periods: 4, scholarshipLimit: 'R$ 3.480, 00' },
    { name: 'CST - Jogos Digitais', mode: 'MOB Semipresencial', periods: 4, scholarshipLimit: 'R$ 4.680, 00' },
    { name: 'CST - Midias Digitais', mode: 'MOB Semipresencial', periods: 4, scholarshipLimit: 'R$ 4.680, 00' },
    { name: 'CST - Mídias Digitais', mode: 'Presencial', periods: 4, scholarshipLimit: 'R$ 9.840, 00' },
    { name: 'CST - Processos Gerenciais', mode: 'MOB Semipresencial', periods: 4, scholarshipLimit: 'R$ 3.480, 00' },
    { name: 'Direito', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 35.790, 00' },
    { name: 'Educação Física - Bacharelado', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 24.912, 00' },
    { name: 'Educação Física - Bacharelado', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 9.360, 00' },
    { name: 'Educação Física - Licenciatura', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 24.912, 00' },
    { name: 'Enfermagem', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 37.080, 00' },
    { name: 'Engenharia Civil', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 40.555, 50' },
    { name: 'Engenharia Civil', mode: 'MOB Semipresencial', periods: 10, scholarshipLimit: 'R$ 14.700, 00' },
    { name: 'Engenharia de Computação', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 35.070, 00' },
    { name: 'Engenharia de Produção', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 35.070, 00' },
    { name: 'Engenharia de Produção', mode: 'MOB Semipresencial', periods: 10, scholarshipLimit: 'R$ 11.700, 00' },
    { name: 'Engenharia de Software', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 24.600, 00' },
    { name: 'Engenharia de Software', mode: 'MOB Semipresencial', periods: 10, scholarshipLimit: 'R$ 11.700, 00' },
    { name: 'Engenharia Elétrica', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 35.070, 00' },
    { name: 'Engenharia Elétrica', mode: 'MOB Semipresencial', periods: 10, scholarshipLimit: 'R$ 14.700, 00' },
    { name: 'Engenharia Mecânica', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 35.070, 00' },
    { name: 'Engenharia Mecânica', mode: 'MOB Semipresencial', periods: 10, scholarshipLimit: 'R$ 14.700, 00' },
    { name: 'Farmácia', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 32.287, 50' },
    { name: 'Farmácia', mode: 'MOB Semipresencial', periods: 10, scholarshipLimit: 'R$ 15.600, 00' },
    { name: 'Filosofia - Bacharelado', mode: 'Presencial', periods: 6, scholarshipLimit: 'R$ 15.948, 00' },
    { name: 'Fisioterapia', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 32.256, 00' },
    { name: 'Fisioterapia', mode: 'MOB Semipresencial', periods: 10, scholarshipLimit: 'R$ 15.600, 00' },
    { name: 'Nutrição', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 29.232, 00' },
    { name: 'Nutrição', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 12.480, 00' },
    { name: 'Pedagogia', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 6.960, 00' },
    { name: 'Psicologia', mode: 'Presencial', periods: 10, scholarshipLimit: 'R$ 39.450, 00' },
    { name: 'Sistema de Informação', mode: 'Presencial', periods: 8, scholarshipLimit: 'R$ 19.680, 00' },
    { name: 'Sistema de Informação', mode: 'MOB Semipresencial', periods: 8, scholarshipLimit: 'R$ 9.360, 00' }
  ];

  public listRank: listRankType[] = [
    {
      id: 1,
      position: 1,
      avatar: this.avatarImg,
      name: 'Caique',
      level: 890,
      score: 1000000,
      me: false,
    },
    {
      id: 2,
      position: 2,
      avatar: this.avatarImg,
      name: 'Emile',
      level: 500,
      score: 450000,
      me: false,
    },
    {
      id: 3,
      position: 3,
      avatar: this.avatarImg,
      name: 'Raquel',
      level: 300,
      score: 400000,
      me: false,
    },
    {
      id: 4,
      position: 4,
      avatar: this.avatarImg,
      name: 'Fernando',
      level: 310,
      score: 124000,
      me: false,
    },
    {
      id: 5,
      position: 5,
      avatar: this.avatarImg,
      name: 'Patrícia',
      level: 100,
      score: 43000,
      me: false,
    },
    {
      id: 6,
      position: 6,
      avatar: this.avatarImg,
      name: 'Carolina',
      level: 62,
      score: 10000,
      me: false,
    },
    {
      id: 7,
      position: 7,
      avatar: this.avatarImg,
      name: 'Felipe',
      level: 35,
      score: 6000,
      me: false,
    },
    {
      id: 8,
      position: 8,
      avatar: this.avatarImg,
      name: 'Rafael',
      level: 20,
      score: 2905,
      me: false,
    },
    {
      id: 9,
      position: 9,
      avatar: this.avatarImg,
      name: 'Rafael',
      level: 20,
      score: 2905,
      me: false,
    },
    {
      id: 10,
      position: 10,
      avatar: this.avatarImg,
      name: 'Pedro Henrique',
      level: 5,
      score: 496,
      me: true,
    },
  ];

  constructor(navCtrl: NavController, platform: Platform,
    public navParams: NavParams,
    public alertCtrl: AlertController, public loadingCtrl: LoadingController,
    public toastCtrl: ToastController, public popOverCtrl: PopoverController, /* public objectives: ObjectivesService, */
    private services: ServicesService, public user: UserService, public util: UtilService,
    public modalCtrl: ModalController, public popoverCtrl: PopoverController, private audio: AudioService) {
    super(navCtrl, platform);
    // TODO reimplementar
    this.challenge = this.navParams.get('challenge');
    const now = new Date();
    this.activeChallenge = this.challenge?.active && this.challenge.start < now && this.challenge.end > now;
    console.log('activeChakllenge? ', this.activeChallenge);
    //start: {$lte: now},
    //end: {$gte: now}
    console.log('challenge: ', this.challenge);
  }



  async ngOnInit() {
  }
  loadRankDetail(type: string) {
    this.rankingDetail = type;
    // Restante do código para carregar desafios
  }
  async closeModal() {
    const popover = await this.popoverCtrl.getTop();

    if (popover) {
      await popover.dismiss(null);
    }
  }
  openLink(link) {
    window.open(link, '_blank');
  }
}
