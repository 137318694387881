import { Component, OnInit } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { eq as CompareVersion } from 'semver';
import { AchievementsService } from '../services/achievements.service';
import { EconomyService } from '../services/economy.service';
import { ObjectivesService } from '../services/objectives.service';
// import { ObjectivesService } from '../services/objectives.service';
import { ServicesService } from '../services/services.service';
import { StatisticsService } from '../services/statistics.service';
import { StorageService } from '../services/storage.service';
import { UserService } from '../services/user.service';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.page.html',
  styleUrls: ['./splash-screen.page.scss'],
})
export class SplashScreenPage implements OnInit {

  className = '';
  randomPositions: any[] = [
    { top: '0vh', left: '0vw' },
    { top: '0vh', left: '0vw' },
    { top: '0vh', left: '0vw' },
    { top: '0vh', left: '0vw' },
    { top: '0vh', left: '0vw' },
  ];
  private error: string;
  private os: string;

  constructor(private achievements: AchievementsService,
    public appVersion: AppVersion,
    private economy: EconomyService, public navCtrl: NavController,
    private objectives: ObjectivesService, public platform: Platform, private services: ServicesService,
    private statistics: StatisticsService,
    public storage: StorageService,
    public toastCtrl: ToastController,
    private user: UserService, private util: UtilService) {
  }

  randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  ngOnInit() {
    this.init();

    setTimeout(() => { this.className = 'bg-splash-3'; }, 5000);

    setTimeout(() => {
      if (this.user.getId()) {
        // this.getDailyObjectives();
        this.achievements.getAchievements();
        this.statistics.getStatistics();
        this.economy.getStore();
        this.util.getAvatarsFromServer(this.user);
        // this.getActiveNewChallenge();
        if (!this.user.getUserInfo().facebookId) { this.util.getCovers(this.user); }
        this.user.calculateLevels();
        this.navCtrl.navigateRoot('tabs/home', this.user.getId());
      } else {
        switch (this.error) {
          case 'update':
            return this.navCtrl.navigateRoot('update');
          case 'maintenance':
            return this.navCtrl.navigateRoot('maintenance');
          default:
            return this.navCtrl.navigateRoot('welcome');
        }
      }
    }, 7000);
  }

  init() {
    //INFO FROM EG-SERVICE
    this.services.get('info').then(res => {
      console.log('get info res: ', res);
      if (this.platform.is('android')) { this.os = 'android'; }
      if (this.platform.is('desktop')) { this.os = 'browser'; }
      if (this.platform.is('ios')) { this.os = 'ios'; }
      // does not check for updates if it is browser
      if (this.os === 'browser') {
        this.services.challengeId = res.challengeID;
        this.services.challengeOver = res.challengeOver;
        this.whoAmI();
      } else {
        this.appVersion.getVersionNumber().then(version => {
          const appVersion = `${version}${this.os ? `-${this.os}` : ''}`;
          const { supportedClientVersions, challengeID, challengeOver } = res;
          // Check if version is supported (request user to update if not)
          console.log('AppVersion: ', appVersion);
          if (supportedClientVersions.some(_version => CompareVersion(_version, appVersion))) {
            this.services.challengeId = challengeID;
            this.services.challengeOver = challengeOver;
            this.whoAmI();
          }
          else {
            console.warn(`App not updated. App version is ${appVersion} but server supports ${supportedClientVersions.join(', ')}`);
            this.error = 'update';
          }
        });
      }
    }).catch(e => {
      // status 503 -> Service Unavailable -> used as maintenance code
      if (e.status === 503) {
        console.warn('Server in maintenance', e);
        this.error = 'maintenance';
      } else {
        console.error('Error trying to comunicate with server', e);
        this.toastCtrl.create({ message: 'Erro ao tentar acessar servidor', duration: 5000 }).then(toast => toast.present());
      }
    });
  }

  async whoAmI() {
    const token = await this.storage.get(`token`);
    console.log('token storage get splash screen: ', token);
    if (token) {
      this.services.setHeaders(token);
      const options = {
        success: res => {
          console.log('res zoasindgsoadfn', res);
          if (res.data.status === 0) {
            return this.services.logout({
              success: () => this.navCtrl.navigateRoot('welcome')
            });
          }
          console.log('sucesso ao acessar whoami', res);
          this.user.setInfo(res.data);
        },
        error: err => {
          console.error('Problema para encontrar usuário pelo token: ', err);
          this.services.deleteHeaders();
        }
      };
      this.services.get('auth/whoami', options);
    }
  }

  getDailyObjectives() {
    // if no paid objectives, then get paid objectives from server
    this.storage.get('weeklyObjectives').then(weeklyObjectives => {
      console.log('weekly objectives', weeklyObjectives);
      if (!weeklyObjectives || !weeklyObjectives.length)
        {this.objectives.getObjectivesFromServer();}
      else {this.storage.get('monthlyObjectives').then(monthlyObjectives => {
        this.objectives.setWeeklyObjectives(weeklyObjectives);
        this.objectives.setMonthlyObjectives(monthlyObjectives);
      });}
    }).catch(e => {
      console.log('getObjectives ERROR', e);
      this.objectives.getObjectivesFromServer();
      console.warn('Error getting objectives', e);
    });
    this.storage.get('dailyObjectives').then((dailyObjectives) => {
      console.log('Daily Objective: ', dailyObjectives);
      if (dailyObjectives) {
        this.storage.get('objectivesStoredTime').then(storedTime => {
          console.log('storedTime', storedTime);
          const now = Date.now();
          const difference = Math.abs(storedTime.date - now) / 36e5;
          if ((difference >= 24 || (storedTime.day !== new Date().getUTCDate() && new Date().getUTCHours() > 6))) {
            // if changed day, then get paid objectives from server
            this.objectives.getObjectivesFromServer();
            console.log('getObjectivesFromServer');
            this.storage.remove('dailyObjectives').then(() => {
              this.storage.remove('objectivesStoredTime').then(() => {
                this.objectives.getDailyObjectivesFromServer();
              });
            });
          } else {
            this.objectives.setObjectives(dailyObjectives);
            console.log('No need to getObjectivesFromServer \nDaily objectives: ', dailyObjectives);
          }
        }).catch(() => {
          console.log('getObjectivesFromServer');
          this.objectives.getDailyObjectivesFromServer();
        });
      } else {
        console.log('getObjectivesFromServer');
        this.storage.remove('dailyObjectives').then(() => {
          this.storage.remove('objectivesStoredTime').then(() => {
            this.objectives.getDailyObjectivesFromServer();
          });
        });
      }
    }).catch(() => {
      console.log('getObjectivesFromServer');
      this.storage.remove('dailyObjectives').then(() => {
        this.storage.remove('objectivesStoredTime').then(() => {
          this.objectives.getDailyObjectivesFromServer();
        });
      });
    });
  }
  getActiveNewChallenge() {
    this.services.get('new-challenges/active', {
      success: res => {
        console.log('res.data challenge: ', res.data);
        // if (res.data && res.data.length > 0) this.objectives.activeChallengeData = res.data[0];
        // console.log('this.objectives.activeChallengeData: ', this.objectives.activeChallengeData);
        // let challenges = res.data;
        // challenges.forEach(challenge => {
        //     challenge.start = new Date(challenge.start);
        //     challenge.startStr = this.util.DateTime(challenge.start);
        //     challenge.end = new Date(challenge.end);
        //     challenge.endStr = this.util.DateTime(challenge.end);
        //     // challenge.endsIn = timeago.format(challenge.end, "pt-BR");
        // });
        // this.challenges = [].concat(challenges);
        // this.challengesTablet = [];
        // while (challenges.length) this.challengesTablet.push(challenges.splice(0, 2));
        // console.log('challenges: ', this.challenges);
      },
      error: e => console.error('Error retrieving active challenges', e)
    });
  }
}
