import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AchievementsService } from './achievements.service';
import { LeaderboardService } from './leaderboard.service';
// import { ObjectivesService } from './objectives.service';
import { SocialService } from './social.service';
import { StatisticsService } from './statistics.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ClearServicesService {

  constructor(public http: HttpClient,
    private achievement: AchievementsService,
    private leaderboard: LeaderboardService,
    // private objectives: ObjectivesService,
    private social: SocialService,
    private statistics: StatisticsService,
    private user: UserService) {
  }

  clear() {
    this.achievement.clearService();
    this.leaderboard.clearService();
    // this.objectives.clearSevice();
    this.social.clearService();
    this.statistics.clearService();
    this.user.clearService();
  }
}
