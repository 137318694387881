import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { AlertController } from '@ionic/angular';
import { ServicesService } from './services.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SocialService {
  /**FACEBOOK*/
  // FACEBOOK_PERMISSIONS: ["email", "public_profile", "user_friends"];
  public isLoggedInFb;

  constructor(
    public alertCtrl: AlertController,
    public http: HttpClient,
    private services: ServicesService,
    private user: UserService
  ) {}

  clearService() {
    this.isLoggedInFb = false;
  }

  // facebookConnect() {
  //   this.fb.login(this.FACEBOOK_PERMISSIONS).then(res => {
  //     if (res.status === "connected") {
  //       console.log("Status = connected", res.status);
  //       let fbUserId = res.authResponse.userID;
  //       let fbToken = res.authResponse.accessToken;
  //       this.services.post('auth/connect-facebook', {
  //         data: { fbUserId, fbToken },
  //         success: res => {
  //           console.info("Sucesso ao connectar conta ao facebook");
  //           this.util.showFeedbackAlert('Sucesso!', 'Você conectou sua conta com o Facebook.');
  //           this.whoAmI();
  //         },
  //         error: err => {
  //           console.error("Erro ao tentar conectar conta com o Facebook: ", err);
  //           switch (err.status) {
  //             case 400:
  //               this.util.showFeedbackAlert('Erro!', "Esta conta do Facebook já está conectada a um usuário do  Enem Game.");
  //               break;
  //             case 401:
  //               this.util.showFeedbackAlert('Erro!', 'Ocorreu um problema para acessar as informações da sua conta do Facebook');
  //               break;
  //             case 403:
  //               this.util.showFeedbackAlert('Erro!', 'Você já está vinculado a uma conta do Facebook');
  //               break;
  //             case 404:
  //               this.util.showFeedbackAlert('Erro!', 'Seu usuário não foi encontrado');
  //               break;
  //             default:
  //               this.util.showFeedbackAlert('Erro!', 'Ocorreu uma falha ao tentar conectar sua conta do Facebook.');
  //               break;
  //           }

  //         }
  //       })
  //     }
  //   })
  // }

  whoAmI() {
    this.services.get('auth/whoami', {
      success: (res) => {
        console.info('Sucesso ao acessar whoami.');
        this.user.setInfo(res.data);
      },
      error: (err) => {
        console.error('Erro ao acessar whoami: ', err);
      },
    });
  }
}
