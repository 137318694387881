import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AvatarCoverPopoverPage } from './avatar-cover-popover.page';
import { AvatarCoverPopoverPageRoutingModule } from './avatar-cover-popover-routing.module';

@NgModule({
  declarations: [
    AvatarCoverPopoverPage,
  ],
  imports: [
    IonicModule.forRoot(),
  ],
})
export class AvatarCoverPopoverPageModule { }
