import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServicesService } from './services.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  public allStatistics;
  public suits;

  constructor(public http: HttpClient, private services: ServicesService) {
  }

  clearService() {
    this.allStatistics = [];
    this.suits = [];
  }

  getStatistics() {
    return this.services.get("analytics/user-game-statistics").then(res => {
      console.info(res, "Success on user statistics get");
      this.allStatistics = res.data;
      this.suits = [{
        name: "CDN",
        img: "assets/imgs/achievements/ciencias-natureza.svg",
        percentage: this.allStatistics.CDN.percentage
      }, {
        name: "CHU",
        img: "assets/imgs/achievements/ciencias-humanas.svg",
        percentage: this.allStatistics.CHU.percentage
      }, {
        name: "LEC",
        img: "assets/imgs/achievements/linguagens-codigos.svg",
        percentage: this.allStatistics.LEC.percentage
      }, {
        name: "MAT",
        img: "assets/imgs/achievements/matematica.svg",
        percentage: this.allStatistics.MAT.percentage
      }];
    });
  }
}
