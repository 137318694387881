import { Component } from '@angular/core';
import { BackButtonEvent } from '@ionic/core';
import { NavController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { SinglePlayerGameplay } from '../single-player-game/single-player-gameplay';
import { AdsService } from 'src/app/services/ads.service';
import { NoUserMatchGameplay } from '../no-user-match/no-user-match-gameplay';
import { QuestionService } from 'src/app/services/question.service';

@Component({
  selector: 'app-single-player-interval',
  templateUrl: './single-player-interval.page.html',
  styleUrls: ['./single-player-interval.page.scss'],
})
export class SinglePlayerIntervalPage {
  public random;
  public displayAdd = true;
  private gameplaycontrol;
  constructor(
    public ads: AdsService, public navCtrl: NavController, public navParams: NavParams,
    public noUserGameplay: NoUserMatchGameplay, public platform: Platform, private questionP: QuestionService,
    public spGameplay: SinglePlayerGameplay, public popoverCtrl: PopoverController) {
    this.navParams.get('gameType') === 'noUser' ? this.gameplaycontrol = this.noUserGameplay : this.gameplaycontrol = this.spGameplay;
    // TODO Analisar alternativa pós migração ionic 3 para 4
    // this.unregisterBackButton = this.platform.registerBackButtonAction(() => this.dismiss(), 20);

    document.addEventListener('ionBackButton', (ev: BackButtonEvent) => {
      ev.detail.register(-1, () => {
        this.dismiss();
      });
    });
  }


  async ionViewDidEnter() {
    this.questionP.questionReady = false;
  }

  ionViewWillLeave() {
    // this.unregisterBackButton();
  }

  dismiss() {
    this.popoverCtrl.dismiss().then(async () => {
      this.gameplaycontrol.nextQuestion();
    });
  }

  openLink(link) {
    window.open(link, '_blank');
  }

}
