import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ParticleFxComponent } from './particle-fx.component';

@NgModule({
    declarations: [ParticleFxComponent],
    imports: [
        RouterModule,
        CommonModule,
        IonicModule.forRoot(),
    ],
    exports: [ParticleFxComponent]
})
export class ParticleFxModule {
}
