import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams, Platform, PopoverController } from '@ionic/angular';
import { AudioService } from 'src/app/services/audio.service';
import { SinglePlayerGameplay } from 'src/app/single-player/single-player-game/single-player-gameplay';

@Component({
  selector: 'abandon-modal',
  templateUrl: 'abandon-modal.html',
  styleUrls: ['./abandon-modal.scss']
})
export class AbandonModalPage {

  public gameplayMode: boolean = false;

  constructor(
    private popoverCtrl: PopoverController, public platform: Platform,
    private router: Router, private audio: AudioService, public navParams: NavParams,
    public spGameplay: SinglePlayerGameplay) {
      if (this.navParams.get('gameType') == 'gameplay') {
        this.gameplayMode = true;
      }
    }

  async closeModal() {
    const popover = await this.popoverCtrl.getTop();

    if (popover) {
      await popover.dismiss(null);
    }
  }

  async abandon() {
    await this.closeModal()
    this.spGameplay.getMatch().ready = false;
    this.router.navigate(['single-player-lobby'])
  }

  toggleFX() {
    this.audio.changeMuteFXState();
  }

  toggleMusic() {
    this.audio.changeMuteMusicState();
  }
}
