import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { MenuController, NavController, Platform, ToastController } from '@ionic/angular';
import { AdsService } from './services/ads.service';
import { AudioService } from './services/audio.service';
import { LogCatcherService } from './services/log-catcher.service';
import { UserService } from './services/user.service';
import { UtilService } from './services/util.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks';
import { SplashScreenPage } from './splash-screen/splash-screen.page';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  // @ViewChild(Nav) nav: Nav;

  public exitFlag: boolean;

  constructor(
    public navCtrl: NavController, private user: UserService, public platform: Platform,
    private ads: AdsService, public toastCtrl: ToastController, public menuCtrl: MenuController,
    private insomnia: Insomnia, public network: Network, private logCatcher: LogCatcherService,
    private audio: AudioService, private util: UtilService, private route: Router, private screenOrientation: ScreenOrientation) {
    this.initializeApp();
  }

  initializeApp() {

    this.route.events.subscribe((obs: any) => {
      // Get the first observable with the first id to prevent loop
      if (obs instanceof NavigationStart && obs.id === 1) {
        // Skip route /forgot-password
        if (!obs.url.includes('/forgot-password')) {
          this.platform.ready().then(() => {
            this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
            this.navCtrl.navigateRoot('/splash-screen');
            // this.navCtrl.navigateRoot('/welcome');
            // Okay, so the platform is ready and our plugins are available.
            // Here you can do any higher level native things you might need.
            // TODO reimplementar
            // this.splashScreen.hide();
            Deeplinks.routeWithNavController(this.navCtrl, { '/splash-screen': SplashScreenPage });
            this.ads.prepareBanner(this.ads.leaderboardBannerOptions, 'leaderboard');
            this.networkInit();
            // this.windowOpenHook();
            this.logCatcher.overrideConsole();
            this.backBtn();
            this.audio.loadApplicationSounds();
            this.clickListener();
            this.audio.verifySoundState();
            if (this.platform.is('mobile')) {
              // this.util.verifyNotificationState();
              //this.askTrackingPermission();
              this.firebaseInit();
              // eslint-disable-next-line no-console
              this.insomnia.keepAwake().then(() => console.info('Insomnia activated successfully'),
                () => console.warn('Error activating Insomnia"'));
            }
          });
        }
      }
    });
  }

  async backBtn() {
    // TODO refazer no ionic novo
    // this.platform.registerBackButtonAction(() => {
    //   /** Sound Effect*/
    //   this.audio.play(this.audio.SFX_ID.BACK);
    //   // closes menu if there is any open
    //   let menu = this.menuCtrl.getOpen();
    //   if (menu) return menu.close();

    //   let activeNavs = this.app.getActiveNavs();
    //   let rootNavs = this.app.getRootNavs();
    //   for (let navs of [activeNavs, rootNavs]) {
    //     let views = navs[navs.length - 1].getViews();
    //     if (views && views.length) {
    //       let lastView = views[views.length - 1];
    //       // if there is any modal (has overlay), then dismiss it
    //       if (lastView.isOverlay) return lastView.dismiss();
    //       // if there is anything in the stack, then return pop it to the previous one
    //       if (views.length > 1) return lastView.dismiss();
    //       if (lastView.name === "TabMenuPage") {
    //         // go to home tab
    //         if (lastView.getContent().getSelected().index !== 2)
    //           return lastView.getContent().select(2);
    //       }
    //     }
    //   }
    //   if (this.exitFlag) return this.platform.exitApp();
    //   else {
    //     this.exitFlag = true;
    //     this.toastCtrl.create({ message: "Pressione novamente para sair", duration: 3000 }).then(toast => toast.present());
    //     return setTimeout(() => this.exitFlag = false, 3000);
    //   }
    // });
  }

  firebaseInit() {
    if (this.util.firebase) { this.util.firebase.grantPermission(); }
    if (this.platform.is('mobile')) {
      if (this.util.firebase) {
        this.util.firebase.setAnalyticsCollectionEnabled(true);
        this.util.firebase.getToken()
          .then(token => {
            console.log(`Firebase token is ${token}`);
            this.user.firebaseTempToken = token;
          })
          .catch(error => console.error('Error getting token', error));
        this.util.firebase.onTokenRefresh().subscribe((token: string) => {
          console.log('token on token refresh: ', token);
          this.user.updateFirebaseToken(token);
        });
      }
    }
  }

  /** Verifies which element was clicked*/
  clickListener() {
    const clickListener = (event: Event) => {
      for (let curElement = event.target as Element; curElement != null; curElement = curElement.parentElement) {
        // console.log('Element: ', curElement);
        if (curElement.tagName === 'BUTTON') {
          /** Sound Effects*/
          if (curElement.className.includes('back-button')) {
            this.audio.play(this.audio.SFX_ID.BACK);
          } else if (curElement.parentElement.className.includes('game-play-btn')) { this.audio.play(this.audio.SFX_ID.SELECT); }
          else if (curElement.id.includes('play-game-button')) { this.audio.play(this.audio.SFX_ID.PLAY); }
          else if (!curElement.parentElement.className.includes('answer-button')) { this.audio.play(this.audio.SFX_ID.CLICK); }
        }
      }
    };
    document.addEventListener('click', clickListener, true);
  }

  /**This hook serves to override the innapbrowser plugin hook*/
  /**Remove it when is no longer needed*/

  // windowOpenHook() {
  //   window["_open"] = window.open;
  //   window["open"] = function open(url, windowName, ...args) {
  //     return window["_open"](url, "_system", ...args);
  //   }
  // }

  private async networkInit() {
    let toast;
    const message = 'Sem conexão com internet';
    if (!this.isOnline()) {
      toast = await this.toastCtrl.create({ message });
      if (this.network.type === 'none') { await toast.present(); }
    }
    this.network.onDisconnect().subscribe(async () => {
      console.warn('network disconnected');
      Promise.resolve(toast ? await toast.dismiss() : '').then(async () => {
        toast = await this.toastCtrl.create({ message });
        await toast.present();
      });
    });
    this.network.onConnect().subscribe(async () => {
      // eslint-disable-next-line no-console
      console.info('network connected');
      if (toast) { await toast.dismiss(); }
    });
    this.platform.resume.subscribe(async () => {
      if (toast) { toast.dismiss(); }
      toast = await this.toastCtrl.create({ message });
      setTimeout(async () => { if (!this.isOnline()) { await toast.present(); } }, 3000);
    });
  }

  private isOnline() { return this.network.type !== 'none'; }

  private askTrackingPermission() {
    if (this.platform.is('ios') /* && this.device.version >= '14.5' */) {
      this.showAppTrackingTransparency();
    }
  }
  private showAppTrackingTransparency() {
    const idfaPlugin = (window as any).cordova.plugins.idfa;
    idfaPlugin.getInfo().then((info) => {
      if (!info.trackingLimited) {
        return info.idfa || info.aaid;
      } else if (
        info.trackingPermission ===
        idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED
      ) {
        return idfaPlugin.requestPermission().then((result) => {
          if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {

            // Start your tracking plugin here!
            this.firebaseInit();

            return idfaPlugin.getInfo().then((_info) => _info.idfa || _info.aaid);
          }
        });
      }
    });
  }
}
