import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { GameMenuComponent } from './game-menu.component';

@NgModule({
    declarations: [GameMenuComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        IonicModule.forRoot(),
    ],
    exports: [GameMenuComponent]
})
export class GameMenuModule {
}
