import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { PrimeService } from 'src/app/services/prime.service';

@Component({
  selector: 'app-login-prime-modal',
  templateUrl: './login-prime-modal.page.html',
  styleUrls: ['./login-prime-modal.page.scss'],
})
export class LoginPrimeModalPage implements OnInit {
  public showPassword = false;
  public form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private prime: PrimeService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      prime_email: [undefined, Validators.required],
      password: [undefined, Validators.required],
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  confirm() {
    this.prime
      .validateCredentials(this.form.value)
      .then((res) => { if (res) { return this.modalCtrl.dismiss(this.form.value.prime_email); } });
  }
}
