import { Component } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { AudioService } from 'src/app/services/audio.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'page-redeem-prize',
    templateUrl: 'redeem-prize.html',
})
export class RedeemPrizePage {
    public objective: any;
    public fx: boolean = false;
    public fxGold: boolean = false;
    public progressBarDuration: number = 1;
    public progressBarBG: string = "2F4184";
    public progressBarColor: string = "#679E4F";

    constructor(public modalCtrl: ModalController,
        public navCtrl: NavController,
        public navParams: NavParams,
        private audio: AudioService,
        public user: UserService) {
        this.objective = this.navParams.get("objective");
    }

    public ionViewDidEnter() {
        console.log('ionViewDidEnter RedeemPrizePage');
        this.changeLevelValues();
        this.changeGoldValues();
    }

    private changeGoldValues() {
        if (this.objective.prizeType !== "coin") return;
        this.user.updateMoney("gain", this.objective.prizeValue);
        this.fxGold = true;
        this.audio.play(this.audio.SFX_ID.PROGRESS_GOLD);
        setTimeout(() => {
            this.fxGold = false;
        }, 1000);
    }

    private changeLevelValues() {
        if (this.objective.prizeType !== "XP") return;
        this.audio.play(this.audio.SFX_ID.PROGRESS_XP);
        let oldLevel = this.user.calculateLevel();
        this.user.setScore(this.objective.prizeValue);
        this.progressBarDuration = 1000;
        if (oldLevel != this.user.calculateLevel()) {
            this.user.levels.progressInLevel = this.user.levels.differenceBetweenLevels;
            setTimeout(() => {
                this.fx = true;
                this.audio.play(this.audio.SFX_ID.LEVEL_UP);
                this.progressBarDuration = 1;
                this.progressBarBG = this.progressBarColor;
                this.user.levels.progressInLevel = 0;
                this.progressBarColor = "#216C32";
                setTimeout(() => { this.fx = false; }, 1000);
                setTimeout(() => {
                    this.progressBarDuration = 1000;
                    this.user.calculateLevels();
                }, 10);
            }, 1100);
        } else {
            this.user.calculateLevels();
        }
    }

    public close() {
        this.modalCtrl.dismiss();
    }
}
