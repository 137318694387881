import { QuestionService } from '../services/question.service';
import { ServicesService } from '../services/services.service';
import { UserService } from '../services/user.service';
import { UtilService } from '../services/util.service';
import { MatchToAnalytics } from './MatchToAnalytics';

export class Match {

    // Number of each tool a user can use per match
    public TOOL_PER_MATCH = { SKIP_TOOL: 1, ANSWER_TWO_TOOL: 1, ELIMINATE_TOOL: 1 };
    //LOCAL ACHIEVEMENTS TO INCREASE
    public achievements: any;

    //ONE MORE CHANCE
    public oneMoreChanceWithVideo;
    public oneMoreChanceWithED;
    //Const
    public ready;
    private startedAt;
    private endedAt;
    //ACTION SCORE CONSTS AND XP TO INCREASE
    private CORRECT_SCORE = { SCORE: 10, TYPE: 'correct' };
    private QUESTION_SEEN_SCORE = { SCORE: 1, TYPE: 'questionSeen' };
    private QUICK_ANSWER_SCORE = { SCORE: 2, TYPE: 'quickAnswer' };
    private BONUS_QUESTION_SCORE = { SCORE: 10, TYPE: 'bonusQuestion' };

    //Match
    private matchToAnalytics;
    //LIFE
    private life = 3;
    private hearts = ['red', 'red', 'red'];
    //QUESTIONS
    private questionNumber = 1;
    //SCORE
    private record;
    private score: any;
    //TOOLS
    private skipToolQuantity = this.TOOL_PER_MATCH.SKIP_TOOL;
    private answerTwoToolQuantity = this.TOOL_PER_MATCH.ANSWER_TWO_TOOL;
    private eliminateToolQuantity = this.TOOL_PER_MATCH.ELIMINATE_TOOL;
    //PROGRESS BAR
    private progressNumbers;
    private progressMarginNum;
    private progressMargin;
    private challengeId;

    constructor(
        private util: UtilService,
        private services: ServicesService,
        private questionP: QuestionService,
        private user: UserService | null) {
        this.begin();
    }

    //GETTERS AND SETTERS

    get totalScore() {
        return this.score.total ? this.score.total.xp : Object.values(this.score).reduce((acc, item: any) => item.xp + acc, 0);
    }

    begin() {
        //Firebase event
        if (this.util.firebase) {
            this.util.firebase.logEvent('single_player_gameplay', { match: 'game_started' });
        }

        this.startedAt = new Date();
        this.questionP.matchTime = 0;
        if (this.user && this.user.getId()) {
            this.record = this.user.getUserInfo().singlePlayerRecord;
            this.matchToAnalytics = new MatchToAnalytics();
        }
        this.progressNumbers = [];
        this.progressMarginNum = -20;
        this.oneMoreChanceWithVideo = true;
        this.oneMoreChanceWithED = true;
        this.progressMargin = this.progressMarginNum + 'px';
        for (let i = 1; i <= 13; i++) {
            this.progressNumbers.push(i);
        }
        this.score = {
            correctAnswer: {
                name: 'Corretas',
                xp: 0,
                quantity: 0
            },
            questionSeen: {
                name: 'Vistas',
                xp: 0,
                quantity: 0
            },
            quickAnswer: {
                name: 'Respostas Rápidas',
                xp: 0,
                quantity: 0
            },
            bonusQuestion: {
                name: 'Bônus',
                xp: 0,
                quantity: 0
            }
        };
        this.achievements = {
            matematicaAchievement: { acronym: 'MAT', name: 'Matemática', toIncrease: 0 },
            cdnAchievement: { acronym: 'CDN', name: 'Ciências da Natureza', toIncrease: 0 },
            chAchievement: { acronym: 'CHU', name: 'Ciências Humanas', toIncrease: 0 },
            lecAchievement: { acronym: 'LEC', name: 'Linguagens e Códigos', toIncrease: 0 },
            gameTimeAchievement: { name: 'Tempo de Jogo', toIncrease: 0 },
            xpAchievement: { name: 'XP', toIncrease: 0 }
        };

        this.ready = true;
    }


    getMatchAnalytics() {
        return this.matchToAnalytics;
    }

    // METHODS

    //MATCH SCORE
    updateScore(type) {
        switch (type) {
            case this.CORRECT_SCORE.TYPE:
                this.score.correctAnswer.xp += this.CORRECT_SCORE.SCORE;
                this.score.correctAnswer.quantity++;
                break;
            case this.QUESTION_SEEN_SCORE.TYPE:
                this.score.questionSeen.xp += this.QUESTION_SEEN_SCORE.SCORE;
                this.score.questionSeen.quantity = this.questionNumber - 1;
                break;
            case this.QUICK_ANSWER_SCORE.TYPE:
                this.score.quickAnswer.xp += this.QUICK_ANSWER_SCORE.SCORE;
                this.score.quickAnswer.quantity++;
                break;
            case this.BONUS_QUESTION_SCORE.TYPE:
                if (this.questionNumber % 5 === 0) {
                    this.score.bonusQuestion.xp += this.BONUS_QUESTION_SCORE.SCORE;
                    this.score.bonusQuestion.quantity++;
                }
                break;
        }
    }

    updateToolQuantity(tool) {
        switch (tool) {
            case 'skipToolQuantity':
                this.skipToolQuantity--;
                break;
            case 'answerTwoToolQuantity':
                this.answerTwoToolQuantity--;
                break;
            case 'eliminateToolQuantity':
                this.eliminateToolQuantity--;
                break;
        }
    }

    end() {
        console.log('end method');
        //Firebase event
        if (this.util.firebase) {
            console.log('enter on firebase util');
            this.util.firebase.logEvent('single_player_gameplay', { match: 'game_finished_match' });
        }
        this.questionP.questionReady = false;
        this.questionP.questionPrepared = false;
        this.endedAt = new Date();
        this.score.questionSeen.xp = this.QUESTION_SEEN_SCORE.SCORE * this.questionNumber;
        this.score.questionSeen.quantity = this.questionNumber;
        const data = {
            questionNumber: this.questionNumber,
            totalScore: this.totalScore,
            record: this.record,
            score: this.score
        };
        this.score.total = { name: 'Total', xp: this.totalScore };
        if (this.user && this.user.getId()) {
            this.matchToAnalytics.end(data);
            this.sendToAnalytics();
            this.matchToAnalytics.ready = false;
        }
        this.ready = false;
    }

    sendToAnalytics() {
        const matchData = this.matchToAnalytics.getAnalyticsInfo();
        console.log('challenge id????', this.challengeId);
        const requestUrl = this.challengeId ? 'matches/receive-challenge-data/' : 'matches/receive-sp-data/';
        console.log('matchData; ', matchData);
        matchData.challengeId = this.challengeId;
        console.log('matchData ater challengeId; ', matchData);

        this.services.post(requestUrl, {
            data: matchData,
            success: res => {
                console.log('Dados enviados com sucesso', res);
                this.user.getPlayerHistories();
            },
            error: err => {
                console.log('Problema ao enviar dados: ', err);
                console.log('Hora do erro:', new Date(), '\nMatch: ', this.matchToAnalytics);
                //todo guardar dados para envio posterior
            }
        });
    }
}
