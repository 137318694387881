import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AvatarsCoversPage } from './avatars-covers';

@NgModule({
  declarations: [
    AvatarsCoversPage,
  ],
  imports: [
    RouterModule,
    CommonModule,
    IonicModule.forRoot(),
  ],
})
export class AvatarsCoversPageModule {}
