import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  AlertController, LoadingController,
  ModalController, NavController,
  NavParams, Platform,
  PopoverController, ToastController
} from '@ionic/angular';
import { AudioService } from 'src/app/services/audio.service';
import { LogCatcherService } from 'src/app/services/log-catcher.service';
import { ServicesService } from 'src/app/services/services.service';
import { UserService } from 'src/app/services/user.service';
import { ConfigModalPage } from '../config-modal/config-modal';

@Component({
  selector: 'bug-report-popover',
  templateUrl: 'bug-report-popover.html',
  styleUrls: ['bug-report-popover.scss']
})

export class BugReportPopoverPage {
  @ViewChild('imageContainer') private imageContainer;

  public reportForm;
  private unregisterBackButton;

  constructor(
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public navParams: NavParams,
    public platform: Platform,
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    public services: ServicesService,
    public formBuilder: UntypedFormBuilder,
    public user: UserService,
    private logCatcher: LogCatcherService,
    private popoverCtrl: PopoverController,
    private audio: AudioService,
    public alertCtrl: AlertController) {
    this.reportForm = this.formBuilder.group({
      bugType: ['', Validators.required],
      report: ['', Validators.required],
    });
  }

  public ionViewWillLeave() {
    // this.unregisterBackButton();
  }

  public async sendReport() {
    if (!this.reportForm.valid) { return; }
    const loading = await this.loadingCtrl.create({ message: 'Coletando dados...' });
    await loading.present();
    const bugType = this.reportForm.controls.bugType.value;
    const report = this.reportForm.controls.report.value;
    this.logCatcher.sendReport(bugType, report).then(async () => {
      await loading.dismiss();
      this.toastCtrl.create({ message: 'Relatório de erro enviado', duration: 3000 }).then(toast => toast.present());
      this.modalCtrl.dismiss();
    }).catch(async e => {
      console.error('Error sending bugreport', e);
      await loading.dismiss();
      this.toastCtrl.create({ message: 'Erro ao enviar relatório de erros', duration: 3000 }).then(toast => toast.present());
    });
  }

  public focusIn() {
    this.imageContainer.nativeElement.classList.add('hide-img');
  }

  public focusOut() {
    this.imageContainer.nativeElement.classList.remove('hide-img');
  }

  async closeModal() {
    const popover = await this.popoverCtrl.getTop();

    if (popover) {
      await popover.dismiss(null);
    }
  }

  async backToConfig() {
    this.audio.play(this.audio.SFX_ID.POPOVER_PRESENT);
    await this.closeModal();
    const popover = await this.popoverCtrl.create({
      component: ConfigModalPage,
      componentProps: { user: this.user, gameType: this.navParams.get('gameType') },
      cssClass: 'big-popover',
      backdropDismiss: true

    });
    await popover.present();
  }
}
