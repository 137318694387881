import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { RoundprogressModule } from 'angular-svg-round-progressbar';
import { AbandonModalPage } from './abandon-modal';

@NgModule({
  declarations: [
    AbandonModalPage,
  ],
  imports: [
    RouterModule,
    CommonModule,
    IonicModule.forRoot(),
    RoundprogressModule
  ],
})
export class AbandonModalPageModule {}
