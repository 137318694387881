import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { AdsService } from '../services/ads.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.page.html',
  styleUrls: ['./base.page.scss'],
})
export class BasePage {

  constructor(public navCtrl: NavController, public platform: Platform) { }

  ngOnInit() {
  }

}
