import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';

const REQUEST_TIMEOUT = 10000;

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  //CHALLENGE INFO
  public challengeId;
  public challengeOver;
  //INITIAL INFO
  public staticUrl;
  public isTablet;
  private serverUrl;
  private apiUrl: string;
  private headers: HttpHeaders;
  private token;

  //CONSTRUCTOR
  constructor(public platform: Platform, public http: HttpClient, private storage: StorageService) {
    this.serverUrl = environment.apiUrl;
    this.apiUrl = this.serverUrl + '/api/';
    this.staticUrl = 'https://services.enemgame.com.br/static/';

    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    // TODO reimplementar
    this.storage.get('token').then((token) => {
      console.log('storage get token');
      if (token) {
        console.log('token: ', token);
        this.token = token;
        this.setHeaders(this.token);
      }
    });

    if (this.platform.width() >= 540) {
      this.isTablet = true;
    } else {
      this.isTablet = false;
    }
  }

  private static returnRequestAsPromise(options) {
    return !options || !(options.success || options.error);
  }

  //GETTERS AND SETTERS

  //Initial Info
  setHeaders(token) {
    this.headers = this.headers.set('Authorization', token);
  }

  async deleteHeaders() {
    this.headers.delete('Authorization');
    await this.storage.remove('token');
  }

  getStaticUrl() {
    return this.staticUrl;
  }

  getApiUrl() {
    return this.apiUrl;
  }

  get(url: string, options?: {
    data?: any | null;
    success?: (res: any, status?: any, xhr?: any) => void | null;
    error?: (e: any) => void | null;
  }) {
    options = options || {};
    let queryString = new HttpParams();
    if (options.data) {
      for (const prop in options.data) {
        if (Object.prototype.hasOwnProperty.call(options.data, prop)) {
          queryString = queryString.append(prop, options.data[prop]);
        }
      }
    }
    const request = () => this.http.get(`${this.apiUrl}${url}`, { headers: this.headers, params: queryString })
      .timeoutWith(REQUEST_TIMEOUT, Observable.throw(new Error('Request timed out')));
    if (ServicesService.returnRequestAsPromise(options)) {
      return new Promise<any>((resolve, reject) => request().subscribe(resolve, reject));
    }
    else {
      request().subscribe(res => {
        if (options.success) { options.success(res); }
      }, err => {
        if (options.error) { options.error(err); }
      });
    }
  }

  post(url: string, options?: {
    data?: any | null;
    success?: (res: any) => void | null;
    error?: (e: any) => void | null;
  }) {
    options = options || {};
    let body = null;
    if (options.data) { body = new HttpParams().set('json', JSON.stringify(options.data)); }
    const request = () => this.http.post(`${this.apiUrl}${url}`, body, { headers: this.headers, observe: 'response' })
      .timeoutWith(REQUEST_TIMEOUT, Observable.throw(new Error('Request timed out')));
    if (ServicesService.returnRequestAsPromise(options)) {
      return new Promise<any>((resolve, reject) => request().subscribe(res => {
        resolve(res.body);
      }, reject));
    }
    else {
      request().subscribe(res => {
        if (options.success) { options.success(res); }
      }, err => {
        if (options.error) { options.error(err); }
      });
    }
  }

  put(url: string, options?: {
    data?: any | null;
    success?: (res: any) => void | null;
    error?: (e: any) => void | null;
  }) {
    options = options || {};
    let body = null;
    if (options.data) { body = new HttpParams().set('json', JSON.stringify(options.data)); }
    const request = () => this.http.put(`${this.apiUrl}${url}`, body, { headers: this.headers, observe: 'response' })
      .timeoutWith(REQUEST_TIMEOUT, Observable.throw(new Error('Request timed out')));;
    if (ServicesService.returnRequestAsPromise(options)) {
      return new Promise<any>((resolve, reject) => request().subscribe(resolve, reject));
    }
    else {
      request().subscribe(res => {
        if (options.success) { options.success(res); }
      }, err => {
        if (options.error) { options.error(err); }
      });
    }
  }

  async getAuthToToken(res) {
    this.token = res.headers.get('Authorization');
    await this.storage.set('token', this.token).then(() => console.log('Token stored'));
    this.setHeaders(this.token);
  }

  login(options: {
    data: {
      username: string; password: string;
    };
    success?: (res: any) => void | null;
    error?: (e: any) => void | null;
  }) {
    console.log('login');
    const _success = options.success;
    const _error = options.error;
    const success = async (res) => {
      console.log('Login success');
      await this.getAuthToToken(res);
      if (_success) { _success(res); }
    };
    const error = (err) => {
      console.error('Login success: ', err);
      console.log(err);
      //todo abrir popup de erro
      console.log('Ops! Ocorreu um erro!', 'Usuário ou senha inválidos.');
      if (_error) { _error(err); }
    };
    options.success = success;
    options.error = error;
    this.post('auth/login', options);
  }

  logout(options: {
    data?: any | null;
    success?: (res: any, status?: any, xhr?: any) => void | null;
    error?: (e: any) => void | null;
  }) {
    const _success = options.success;
    const _error = options.error;
    const success = async (res, status, xhr) => {
      await this.storage.remove('token').then(() => console.log('Token removed'));
      if (_success) { _success(res, status, xhr); }
    };
    const error = (err) => {
      console.error(err);
      if (_error) { _error(err); }
    };
    options.success = success;
    options.error = error;
    this.get('auth/logout', options);
  }

  register(options: {
    data: {
      firstName: string; username: string; email: string; password: string;
    };
    success?: (res: any) => void | null;
    error?: (e: any) => void | null;
  }) {
    const _success = options.success;
    const _error = options.error;
    const success = async (res) => {
      await this.getAuthToToken(res);
      if (_success) { _success(res); }
    };
    const error = (err) => {
      console.error('post register error: ', err);
      if (err.status === 403) {
        const invalidKeys = Object.keys(err.error.data);
        //todo abrir popup de erro
        console.error('Ops! Ocorreu um erro!',
          `${invalidKeys.length > 1
            ? invalidKeys.slice(0, invalidKeys.length - 1) + ' e '
            : ''}${invalidKeys[invalidKeys.length - 1]} já existe${invalidKeys.length > 1
              ? 'm'
              : ''}`);
      }
      if (_error) { _error(err); }
    };
    options.success = success;
    options.error = error;
    this.post('auth/full-register', options);
  }

  fbLogin(options: {
    data: {
      fbUserId: string; fbToken: string;
    };
    success?: (res: any) => void | null;
    error?: (e: any) => void | null;
  }) {
    const _success = options.success;
    const _error = options.error;
    const success = (res) => {
      this.getAuthToToken(res);
      if (_success) { _success(res); }
    };
    const error = (err) => {
      console.error(err);
      //todo abrir popup de erro
      console.error('Ops! Ocorreu um erro!', 'Usuário ou senha inválidos.');
      if (_error) { _error(err); }
    };
    options.success = success;
    options.error = error;
    this.post('auth/facebook', options);
  }

  delete(url: string, options?: {
    data?: any | null;
    success?: (res: any) => void | null;
    error?: (e: any) => void | null;
  }) {
    options = options || {};
    let body = null;
    if (options.data) { body = new HttpParams().set('json', JSON.stringify(options.data)); }
    const request = () => this.http.delete(`${this.apiUrl}${url}`, { headers: this.headers, observe: 'response' })
      .timeoutWith(REQUEST_TIMEOUT, Observable.throw(new Error('Request timed out')));
    if (ServicesService.returnRequestAsPromise(options)) {
      return new Promise<any>((resolve, reject) => request().subscribe(res => {
        resolve(res.body);
      }, reject));
    }
    else {
      request().subscribe(res => {
        if (options.success) { options.success(res); };
      }, err => {
        if (options.error) { options.error(err); };
      });
    }
  }

  resetPassword(url: string,
    options?: {
      headers?: HttpHeaders;
      data?: any | null;
      success?: (res: any) => void;
      error?: (err: any) => Promise<void>;
    }) {
    options = options || {};
    let body = null;
    if (options.data) { body = new HttpParams().set('json', JSON.stringify(options.data)); }
    const request = () => this.http.post(`${this.apiUrl}${url}`, body, { headers: options.headers, observe: 'response' });
    if (ServicesService.returnRequestAsPromise(options)) {
      return new Promise<any>((resolve, reject) => request().subscribe((res: any) => {
        resolve(res.body);
      }, reject));
    }
    else {
      request().subscribe(res => {
        if (options.success) { options.success(res); }
      }, err => {
        if (options.error) { options.error(err); }
      });
    }
  }

}
