import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, PopoverController } from '@ionic/angular';

@Component({
    selector: 'page-active-objectives-popover',
    templateUrl: 'active-objectives-popover.html',
})
export class ActiveObjectivesPopoverPage implements OnInit {

    public dailyObjectives;

    constructor(public popoverCtrl: PopoverController, public navCtrl: NavController, public navParams: NavParams) {
    }

    ngOnInit() {
        console.log('ngOnInit ActiveObjectivesPopoverPage');
        this.dailyObjectives = this.navParams.get('objectives');
    }

    public async dismiss(){
        await this.popoverCtrl.dismiss();
    }
}
