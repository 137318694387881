import { Injectable } from '@angular/core';
import { Platform } from "@ionic/angular";
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { UserService } from './user.service';
import { ServicesService } from './services.service';

@Injectable({
  providedIn: 'root'
})
export class LogCatcherService {

  public logs: any[] = [];
  private methods;

  constructor(
    public appVersion: AppVersion, 
    public device: Device, 
    public network: Network,
    public platform: Platform, private services: ServicesService, private user: UserService) {
    this.methods = ["log", "error", "warn", "info"];
  }

  public overrideConsole() {
    this.methods.forEach(method => {
      const self = this;
      if (!console.hasOwnProperty(method)) return;
      let originalConsole = console[method];
      console[method] = function (...args) {
        try {
          JSON.stringify(args);
          self.logs.push({ method, time: new Date().toISOString(), content: args });
        } catch (e) { }
        finally { originalConsole(...args); }
      }
    });
  }

  private getAppInfo() {
    if (this.platform.is("mobile")) return Promise.all([
      this.appVersion.getAppName(),
      this.appVersion.getPackageName(),
      this.appVersion.getVersionNumber(),
      this.appVersion.getVersionCode()
    ]).then(([app, packageName, version, versionCode]) => ({ app, packageName, version, versionCode }));
    return Promise.resolve({});
  }

  public sendReport(type: string, text: string) {
    return this.getAppInfo().then(app => {
      let device = {
        cordova: this.device.cordova, virtual: this.device.isVirtual, os: this.device.platform,
        osVersion: this.device.version, manufacturer: this.device.manufacturer, model: this.device.model,
        uuid: this.device.uuid, serialNumber: this.device.serial, cpuCores: navigator.hardwareConcurrency,
        ram: navigator["deviceMemory"], maxTouchPoints: navigator.maxTouchPoints, userAgent: navigator.userAgent
      };
      console.log('device: ', device);
      let language = navigator.language;
      let networkType = this.network.type;
      let screen = {
        width: window.screen.width, height: window.screen.height, colorDepth: window.screen.colorDepth,
        pixelDepth: window.screen.pixelDepth, pixelRatio: window.devicePixelRatio, realWidth: window.devicePixelRatio * window.screen.width,
        realHeight: window.devicePixelRatio * window.screen.height
      };
      let data = {
        bugType: type, report: text,
        gameLog: JSON.stringify(this.logs), userId: this.user.getId(), app, device, language, networkType, screen
      };
      return this.services.post("bug-report", { data });
    });
  }
}
