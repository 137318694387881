import { Component } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavParams,
  Platform,
  PopoverController
} from '@ionic/angular';
import { PrimeService } from 'src/app/services/prime.service';
import { UserService } from 'src/app/services/user.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AudioService } from 'src/app/services/audio.service';

@Component({
  selector: 'prime-financial-renew',
  templateUrl: 'prime-financial-renew.html',
  styleUrls: ['prime-financial-renew.scss'],
  providers: [InAppBrowser],
})
export class PrimeFinancialRenewPage {
  inAppBwr: any;
  iframe: HTMLIFrameElement;
  options = 'location=yes,footer=yes,hidenavigationbuttons=no,fullscreen=yes';

  constructor(
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private prime: PrimeService,
    public user: UserService,
    private iab: InAppBrowser,
    private audio: AudioService,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private platform: Platform
  ) { }

  async closeModal() {
    const popover = await this.popoverCtrl.getTop();
    if (this.audio.muteMusic === false && this.platform.ready()) {
      this.audio.loop(this.navParams.get('musicID'));
    }
    if (popover) {
      await popover.dismiss(null);
    }
  }

  getPrime() {
    if (!this.platform.is('cordova')) {
      window.open('https://www.enemgameprime.com.br/autenticar', '_system');
    } else {
      this.iab.create(
        'https://www.enemgameprime.com.br/autenticar',
        '_self',
        this.options
      );
    }
  }

  async presentPrimeLogin() {
    // await this.popoverCtrl.create({
    //   component: LoginPrimeModalPage,
    //   componentProps: { musicID: this.audio.MUSIC_ID.MENU },
    //   cssClass: 'small-popover',
    //   backdropDismiss: true
    // }).then(popover => popover.present());
  }
}
