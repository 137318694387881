import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LoginPrimeModalPageModule } from '../login-prime-modal/login-prime-modal.module';
import { PrimeFinancialRenewPage } from './prime-financial-renew';

@NgModule({
  declarations: [
    PrimeFinancialRenewPage,
  ],
  imports: [
    RouterModule,
    CommonModule,
    IonicModule.forRoot(),
    LoginPrimeModalPageModule,
  ],
})
export class PrimeFinancialRenewPageModule {}
