import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LoginPrimeModalPageModule } from '../login-prime-modal/login-prime-modal.module';
import { PrimeModalPage } from './prime-modal';

@NgModule({
  declarations: [
    PrimeModalPage,
  ],
  imports: [
    RouterModule,
    CommonModule,
    IonicModule.forRoot(),
    LoginPrimeModalPageModule,
  ],
})
export class PrimeModalPageModule {}
