import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { RedeemPrizePage } from './redeem-prize';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ParticleFxModule } from "../../components/particle-fx/particle-fx.module";


@NgModule({
  declarations: [
    RedeemPrizePage,
  ],
  imports: [
    CommonModule,
    RoundProgressModule,
    ParticleFxModule,
    IonicModule.forRoot(),
  ],
})
export class RedeemPrizePageModule { }
