import { Component, OnInit } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import {
  AlertController,
  ModalController,
  NavParams,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { AudioService } from 'src/app/services/audio.service';
import { PrimeService } from 'src/app/services/prime.service';
import { UserService } from 'src/app/services/user.service';
import { LoginPrimeModalPage } from '../login-prime-modal/login-prime-modal.page';


@Component({
  selector: 'prime-modal',
  templateUrl: 'prime-modal.html',
  styleUrls: ['prime-modal.scss'],
  providers: [InAppBrowser],
})
export class PrimeModalPage implements OnInit {
  inAppBwr: any;
  iframe: HTMLIFrameElement;
  options = 'location=yes,footer=yes,hidenavigationbuttons=no,fullscreen=yes';

  constructor(
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private prime: PrimeService,
    private toastCtrl: ToastController,
    public user: UserService,
    private iab: InAppBrowser,
    private audio: AudioService,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private platform: Platform,
    private screenOrientation: ScreenOrientation
  ) { }

  ngOnInit(): void {
    this.screenOrientation.unlock();
    if (!this.audio.muteMusic) {
      this.audio.stopLoop();
    }
  }

  async closeModal() {
    const popover = await this.popoverCtrl.getTop();
    if (!this.audio.muteMusic) {
      this.platform
        .ready()
        .then(() => this.audio.restartLastLoopAudio());
    }
    if (popover) {
      await popover.dismiss(null);
    }
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  }

  getPrime() {
    if (!this.platform.is('cordova')) {
      window.open('https://www.enemgameprime.com.br/', '_system');
    } else {
      this.iab.create(
        'https://www.enemgame.com.br/prime/',
        '_self',
        this.options
      );
    }
  }

  async presentPrimeLogin() {
    const modal = await this.modalCtrl.create({
      component: LoginPrimeModalPage,
      cssClass: 'transparent-modal'
    });

    modal.onDidDismiss()
      .then(props => {
        if (props.data) {
          this.user.prime_email = props.data;
          this.closeModal();
        }
      });
    modal.present();
  }
}
