import { Injectable } from '@angular/core';
import { QuestionService } from 'src/app/services/question.service';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { Question } from '../../models/Question';
import { Match } from '../../models/Match';
import { AdsService } from 'src/app/services/ads.service';
import { AudioService } from 'src/app/services/audio.service';
import { SinglePlayerIntervalPage } from '../single-player-interval/single-player-interval.page';
import { ServicesService } from 'src/app/services/services.service';
import { UtilService } from 'src/app/services/util.service';

@Injectable()
export class NoUserMatchGameplay {

    /**CLASS CONSTS*/
    //private ANSWER = "answer";

    /**CLASS*/
    private question;
    private match;

    /**GAMEPLAY INFO*/
    private correctQuestionAnswer;

    /**CONTRUCTOR*/
    constructor(public modalCtrl: ModalController,
        private services: ServicesService,
        private ads: AdsService,
        private questionP: QuestionService,
        public toastCtrl: ToastController,
        private platform: Platform,
        private audio: AudioService,
        private util: UtilService) {
        if (!this.match) { this.newMatch(); }
    }

    /**GETTERS AND SETTERS*/

    getQuestion() {
        return this.question;
    }

    /**METHODS*/

    /**QUESTION METHODS*/

    /**ANSWER*/
    answer(event, alternative, acronym) {
        this.correctQuestionAnswer = false;
        this.lastQuestion(acronym, alternative);
        this.question.setAlternativeSelected(alternative.isSelected = true);
        this.question.answerQuantity--;
    }

    selectAlternative(event, alternative, acronym) {
        this.question.setAlternativeSelected(alternative.isSelected = true);
        this.question.answerQuantity--;
        if (this.question.answerTwoSelectedAlternatives.length == 0) {
            this.question.answerTwoSelectedAlternatives.push(alternative);
        } else {
            this.question.answerTwoSelectedAlternatives.push(alternative);
            this.lastQuestion(acronym, alternative);
        }
    }

    lastQuestion(acronym, alternative) {
        /**Clear question timer and update quick answer score*/
        clearInterval(this.question.timer);
        /**QUESTION ANSWER CORRECT OR INCORRECT*/
        if (this.question.answerTwo) {
            this.question.answerTwoSelectedAlternatives.forEach((alternativeOfTwo) => {
                if (alternativeOfTwo.isCorrect) { this.correctQuestionAnswer = true; }
            });
        } else {
            if (alternative.isCorrect) { this.correctQuestionAnswer = true; }
        }
        window.setTimeout(() => {
            this.questionResult();
        }, 3000);
    }

    correctAnswer() {
        /** Sound Effect*/
        this.audio.play(this.audio.SFX_ID.CORRECT);
        /**Firebase event*/
        if (this.util.firebase) {
            this.util.firebase.logEvent('single_player_gameplay', { question_answered: 'game_question_correct' });
        }

        this.match.updateScore(this.match.CORRECT_SCORE.TYPE);
        if (this.match.questionNumber % 5 === 0 && this.match.questionNumber > 0) {
            this.match.updateScore(this.match.BONUS_QUESTION_SCORE.TYPE);
        }
        if (this.question.questionTime <= 180) { this.match.updateScore(this.match.QUICK_ANSWER_SCORE.TYPE); }
    }

    incorrectAnswer() {
        /**Firebase event*/
        if (this.util.firebase) { this.util.firebase.logEvent('single_player_gameplay', { question_answered: 'game_question_wrong' }); }
        this.match.life--;
        this.match.hearts[this.match.life] = 'death';
        /** Sound Effect*/
        this.match.life > 0 ? this.audio.play(this.audio.SFX_ID.INCORRECT) : this.audio.play(this.audio.SFX_ID.GAMEOVER);
        /**Match End*/
        if (this.match.life <= 0 && (!this.match.oneMoreChanceWithVideo && !this.match.oneMoreChanceWithED)) {
            // this.match.end();
        } else if (this.match.life === 1 && this.platform.is('cordova')) {
            this.ads.prepareRewardVideo();
            if (this.ads.timeToShowAd && (this.match.oneMoreChanceWithVideo && this.match.oneMoreChanceWithED)) {
                this.ads.prepareInterstitial();
            }
        }
    }

    questionResult() {
        /**Clean data question*/
        if (this.question.answerQuantity <= 0) {
            this.question.setQuestionAnswered(true);
            /**Update answer score*/
            if (this.correctQuestionAnswer) {
                this.correctAnswer();
            }
            if (!this.correctQuestionAnswer) {
                this.incorrectAnswer();
            }

            if (this.match.life > 0 || (this.match.oneMoreChanceWithVideo || this.match.oneMoreChanceWithED)) {
                if (this.match.life > 0) {
                    window.setTimeout(() => {
                        console.log('isAlive');
                        this.presentIntervalModal();
                    }, 3500);
                }
                window.setTimeout(() => {
                    this.questionP.newQuestion(this.match.getMatchAnalytics(), this.match.questionNumber);
                    this.question = new Question(this.questionP);
                }, 4000);
            }
        }
    }

    async presentIntervalModal() {
        const intervalModal = await this.modalCtrl.create({ component: SinglePlayerIntervalPage, componentProps: { gameType: 'noUser' } });
        await intervalModal.present();
    }

    nextQuestion() {
        this.match.updateScore(this.match.QUESTION_SEEN_SCORE.TYPE);
        const questionInterval = setInterval(() => {
            if (this.questionP.questionPrepared) {
                this.moveBar();
                this.questionP.questionReady = true;
                this.audio.play(this.audio.SFX_ID.SLIDEIN);
                this.question.questionTimer();
                this.match.questionNumber++;
                clearInterval(questionInterval);
                this.questionP.questionPrepared = false;
            }
        }, 1000);
    }

    /**MOVE PROGRESS BAR*/

    public moveBar() {
        this.match.progressMarginNum += -72;
        this.match.progressMargin = this.match.progressMarginNum + 'px';
        if (this.match.questionNumber > 4) { this.match.progressNumbers.push(this.match.questionNumber + 9); }
    }

    getMatch() {
        return this.match;
    }

    newQuestion() {
        this.question = new Question(this.questionP);
    }

    newMatch() {
        this.match = new Match(this.util, this.services, this.questionP, null);
        this.question = new Question(this.questionP);
        this.match.oneMoreChanceWithED = false;
        // this.noUserGameplay.getMatch().oneMoreChanceWithED
    }
}
