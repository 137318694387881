import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { ActiveObjectivesPopoverPage } from '../modals/active-objectives-popover/active-objectives-popover';
import { ServicesService } from './services.service';
import { AudioService } from './audio.service';
import { UserService } from './user.service';
import { StorageService } from './storage.service';

const keysToClear = ['dailyObjectives', 'weeklyObjectives', 'monthlyObjective', 'objectivesStoredTime'];

@Injectable({
  providedIn: 'root'
})
export class ObjectivesService {

  private dailyObjectivesUrl;
  private objectivesUrl: string;
  private bonusUrl;
  private dailyObjectives: any[];
  public weeklyObjectives: any[];
  public monthlyObjectives: any[];
  public objectivesPopoverReadyToSee;
  public activeChallengeData;

  /**Objectives Current Values per Action Types*/
  constructor(public alertCtrl: AlertController, private audio: AudioService, public http: HttpClient,
    public popoverCtrl: PopoverController, private services: ServicesService,
    private storage: StorageService,
    private user: UserService) {
    this.dailyObjectivesUrl = 'daily-objectives/';
    this.bonusUrl = 'daily-objectives/bonus/';
    this.objectivesUrl = 'objectives/';
  }

  public clearSevice() {
    this.dailyObjectives = this.weeklyObjectives = this.monthlyObjectives = [];
    return Promise.all(keysToClear.map(key => this.storage.remove(key))).then(() => true).catch(e => {
      console.info('error clearing objectives storage', e);
      return false;
    });
  }

  getDailyObjectivesFromServer() {
    this.services.get(this.dailyObjectivesUrl, {
      success: res => {
        console.log('res objectives', res);
        this.dailyObjectives = res.data;
        this.dailyObjectives.forEach(objective => {
          if (objective.action !== 'bonus') {
            if (objective.completed == true) {
              objective.currentValue = objective.requirement;
              objective.percentage = objective.currentValue * 100 / objective.requirement;
            } else {objective.currentValue = objective.percentage = 0;}
          } else {
            objective.currentValue = this.dailyObjectives.filter(objective => objective.completed == true).length;
            objective.currentValue = Math.min(objective.currentValue, objective.requirement);
            objective.percentage = objective.currentValue * 100 / objective.requirement;
          }
        });
        this.storage.set('dailyObjectives', this.dailyObjectives).then(() => {
          console.log('Daily objectives stored');
          if (this.user.getUserInfo().profile.state && this.user.getUserInfo().profile.state != 'none') {this.openObjectivesAlert();}
          this.objectivesPopoverReadyToSee = true;
          this.storage.set('objectivesStoredTime', {
            date: Date.now(),
            day: new Date().getUTCDate(),
            hour: new Date().getUTCHours()
          }).then((res) => console.log('ObjectivesStoredTime: ', res));
        });
      },
      error: err => {
        console.error('Error on daily-objectives get: ', err);
      }
    });
  }

  public getObjectivesFromServer() {
    let localWeeklyObjectives; let localMonthlyObjectives;
    return this.storage.get('weeklyObjectives').then(objectives => {
      localWeeklyObjectives = objectives;
      return this.storage.get('monthlyObjectives');
    }).then(objectives => {
      localMonthlyObjectives = objectives;
      return this.services.get(this.objectivesUrl);
    }).then(res => {
      const objectives = res.data.map(objective => {
        if (objective.completed) {
          objective.currentValue = objective.requirement;
          objective.percentage = 100;
        } else {objective.currentValue = objective.percentage = 0;}
        return objective;
      });
      const { weekly, monthly } = objectives.reduce((obj, objective) => {
        if (objective.type === 'week') {obj.weekly.push(objective);}
        else if (objective.type === 'month') {obj.monthly.push(objective);}
        return obj;
      }, { weekly: [], monthly: [] });
      if (!localWeeklyObjectives || !localWeeklyObjectives.length || localWeeklyObjectives[0].start !== weekly[0].start) {
        this.setWeeklyObjectives(weekly);
        this.storage.set('weeklyObjectives', weekly).then(() => console.info('updated weekly objectives'));
      } else {this.setWeeklyObjectives(localWeeklyObjectives);}
      if (!localMonthlyObjectives || !localMonthlyObjectives.length || localMonthlyObjectives[0].start !== monthly[0].start) {
        this.setMonthlyObjectives(monthly);
        this.storage.set('monthlyObjectives', monthly).then(() => console.info('updated monthly objectives'));
      } else {this.setMonthlyObjectives(localMonthlyObjectives);}
      console.log('LOADED OBJECTIVES');
    });
  }

  postCompletedDailyObjectiveToServer(objective) {
    let url;
    if (objective.action == 'bonus') {url = this.bonusUrl;} else {url = this.dailyObjectivesUrl;}
    return this.services.post(url, { data: { dailyObjectiveId: objective.id } }).then(res => {
      objective.completed = true;
      const bonus = this.dailyObjectives.find(objective => objective.action == 'bonus');
      bonus.currentValue++;
      bonus.currentValue = Math.min(bonus.currentValue, bonus.requirement);
      bonus.percentage = bonus.currentValue * 100 / bonus.requirement;
      this.storage.set('dailyObjectives', this.dailyObjectives).then(() => console.log('Daily objectives stored'));
      return res.data;
    }).catch(err => {
      console.error('Erro ao tentar registrar conclusão do objetivo: ', err);
      if (err.status == 401) {
        objective.completed = true;
        this.storage.set('dailyObjectives', this.dailyObjectives).then(() => console.log('Daily objectives stored'));
      } else {
        objective.completed = false;
      }
      throw err;
    });
  }

  public postCompletedObjectiveToServer(objective) {
    return this.services.post(this.objectivesUrl, { data: { objectiveId: objective.id } }).then(res => {
      console.info('Objective completion stored successfully', res);
      objective.completed = true;
      return this.storage.set(`${objective.type}lyObjectives`, this[`${objective.type}lyObjectives`]).then(() => true);
    });
  }

  public registerPaidObjective(objective) {
    return this.services.post(`${this.objectivesUrl}/${objective.id}`).then(res => {
      console.log('week', this.weeklyObjectives);
      console.log('month', this.monthlyObjectives);
      objective.subscribed = true;
      return this.storage.set(`${objective.type}lyObjectives`, this[`${objective.type}lyObjectives`]).then(() => true);
    });
  }

  getObjectives() {
    return this.dailyObjectives;
  }

  setObjectives(dailyObjectives) {
    this.dailyObjectives = dailyObjectives;
  }

  setWeeklyObjectives(objectives) {
    this.weeklyObjectives = [].concat(objectives);
    this.weeklyObjectives.forEach(objective => {
      objective.start = new Date(objective.start);
      objective.end = new Date(objective.end);
    });
    return this.weeklyObjectives;
  }

  setMonthlyObjectives(objectives) {
    this.monthlyObjectives = [].concat(objectives);
    this.monthlyObjectives.forEach(objective => {
      objective.start = new Date(objective.start);
      objective.end = new Date(objective.end);
    });
    return this.monthlyObjectives;
  }

  updateObjetiveValue(objectiveAction, num) {
    ['daily', 'weekly', 'monthly'].forEach(type => {
      const objectives = this[`${type}Objectives`].filter(objective => (objective.subscribed || type === 'daily') && objective.action === objectiveAction);
      objectives.forEach(objective => {
        if (!objective || objective.currentValue == objective.requirement || objective.completed) {return;}
        objective.currentValue += num;
        objective.currentValue = Math.min(objective.currentValue, objective.requirement);
        objective.percentage = objective.currentValue * 100 / objective.requirement;
      });
      this.storage.set(`${type}Objectives`, this[`${type}Objectives`])
        .catch(err => console.error(`${type} objectives were not stored: `, err));
    });
    const objective = this.dailyObjectives.find(objective => objective.action == objectiveAction);
    if (!objective || objective.currentValue == objective.requirement) {return;}
    if (this.dailyObjectives.filter(objective => objective.requirement == objective.currentValue).length == 1 && objective.currentValue == objective.requirement) {this.audio.play(this.audio.SFX_ID.POPOVER_PRESENT);}
  }

  updateObjectivePerSuit(suit, num) {
    suit = suit.toLowerCase();
    if (suit === 'ing' || suit === 'esp') {suit = 'lin';} // turn ESP and ING to LIN
    this.updateObjetiveValue(`${suit}-question`, num);
  }

  public get objectivesToRedeem() {
    let toRedeem = 0;
    if (this.dailyObjectives) {
      this.dailyObjectives.forEach(objective => {
        if (objective.percentage == 100 && !objective.completed) {
          toRedeem++;
        }
      });
    }
    return toRedeem;
  }

  public get bonusObjectiveRedeemStatus() {
    let bonusObjective;
    if (this.dailyObjectives) {
      bonusObjective = this.dailyObjectives.find(objective => objective.action == 'bonus');
      return bonusObjective.completed;
    } else {return true;}
  };

  async openObjectivesAlert() {
    const objectivesPopover = await this.popoverCtrl.create({
      component: ActiveObjectivesPopoverPage, componentProps: { objectives: this.dailyObjectives },
      cssClass: 'pop-daily pop-default ',
      showBackdrop: true,
      backdropDismiss: true
    });
    await objectivesPopover.present();
    this.objectivesPopoverReadyToSee = false;
    this.audio.play(this.audio.SFX_ID.POPOVER_PRESENT);
  }

  public challengeInProgress(challenge) {
    const now = new Date();
    console.log('challenge: ', challenge);
    console.log('challengeInProgress ? ', challenge.active && challenge.start < now && challenge.end > now);
    return challenge.active && challenge.start < now && challenge.end > now;
  }

  public challengeOver(challenge) {
    const now = new Date();
    return challenge.active && challenge.end > now;
  }
}
