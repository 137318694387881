import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import IntlFallback from 'intl';
import { ServicesService } from './services.service';
import { StorageService } from './storage.service';

const IntlAPI = Intl || IntlFallback;

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  /**CONSTS*/
  public FB_API_VERSION;

  notificationToggle: boolean;
  notificationStateVerified: boolean;
  notificationOff: boolean;

  public TwoFractionDigits = IntlAPI.NumberFormat('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format;
  public Percentage = IntlAPI.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format;
  public DateTime = IntlAPI.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).format;
  public Round = IntlAPI.NumberFormat('pt-BR', { maximumFractionDigits: 0 }).format;
  public static_url = 'https://services.enemgame.com.br/static/avatars/md/';
  public avatars = [
    {
      id: '1',
      image: '57f7b3a0-4579-11e7-84b3-2998a2c539d1.jpg',
      selected: false,
      link: this.static_url + '57f7b3a0-4579-11e7-84b3-2998a2c539d1.jpg'
    },
    {
      id: '2',
      image: '57f8ec20-4579-11e7-84b3-2998a2c539d1.jpg',
      selected: false,
      link: this.static_url + '57f8ec20-4579-11e7-84b3-2998a2c539d1.jpg'
    },
    {
      id: '3',
      image: '57f93a40-4579-11e7-84b3-2998a2c539d1.jpg',
      selected: false,
      link: this.static_url + '57f93a40-4579-11e7-84b3-2998a2c539d1.jpg'
    },
    {
      id: '4',
      image: '57f96150-4579-11e7-84b3-2998a2c539d1.jpg',
      selected: false,
      link: this.static_url + '57f96150-4579-11e7-84b3-2998a2c539d1.jpg'
    },
    {
      id: '5',
      image: '57f98860-4579-11e7-84b3-2998a2c539d1.png',
      selected: false,
      link: this.static_url + '57f98860-4579-11e7-84b3-2998a2c539d1.png'
    },
    {
      id: '6',
      image: '57f9af70-4579-11e7-84b3-2998a2c539d1.png',
      selected: false,
      link: this.static_url + '57f9af70-4579-11e7-84b3-2998a2c539d1.png'
    },
    {
      id: '7',
      image: '57f9d680-4579-11e7-84b3-2998a2c539d1.png',
      selected: false,
      link: this.static_url + '57f9d680-4579-11e7-84b3-2998a2c539d1.png'
    },
    {
      id: '8',
      image: '57f9fd90-4579-11e7-84b3-2998a2c539d1.png',
      selected: false,
      link: this.static_url + '57f9fd90-4579-11e7-84b3-2998a2c539d1.png'
    },
    {
      id: '9',
      image: '57fa24a0-4579-11e7-84b3-2998a2c539d1.png',
      selected: false,
      link: this.static_url + '57fa24a0-4579-11e7-84b3-2998a2c539d1.png'
    },
    {
      id: '10',
      image: '57fa4bb0-4579-11e7-84b3-2998a2c539d1.png',
      selected: false,
      link: this.static_url + '57fa4bb0-4579-11e7-84b3-2998a2c539d1.png'
    },
    {
      id: '11',
      image: '57fa72c0-4579-11e7-84b3-2998a2c539d1.png',
      selected: false,
      link: this.static_url + '57fa72c0-4579-11e7-84b3-2998a2c539d1.png'
    },
    {
      id: '12',
      image: '57fa99d0-4579-11e7-84b3-2998a2c539d1.png',
      selected: false,
      link: this.static_url + '57fa99d0-4579-11e7-84b3-2998a2c539d1.png'
    },
    {
      id: '13',
      image: '57fae7f0-4579-11e7-84b3-2998a2c539d1.png',
      selected: false,
      link: this.static_url + '57fae7f0-4579-11e7-84b3-2998a2c539d1.png'
    },
    // {
    //   id: '14',
    //   image: 'fa8c1f60-15ae-11e8-aa47-3fb363b47b04.png',
    //   selected: false,
    //   link: this.static_url + 'fa8c1f60-15ae-11e8-aa47-3fb363b47b04.png'
    // },
  ];
  /** Cover list*/
  public covers = [{
    id: 'c1',
    image: 'c1.jpg',
    selected: false,
    link: 'assets/imgs/covers/c1.jpg'
  }, {
    id: 'c2',
    image: 'c2.jpg',
    selected: false,
    link: 'assets/imgs/covers/c2.jpg'
  }, {
    id: 'c3',
    image: 'c3.jpg',
    selected: false,
    link: 'assets/imgs/covers/c3.jpg'
  }, {
    id: 'c4',
    image: 'c4.jpg',
    selected: false,
    link: 'assets/imgs/covers/c4.jpg'
  }, {
    id: 'c5',
    image: 'c5.jpg',
    selected: false,
    link: 'assets/imgs/covers/c5.jpg'
  }, {
    id: 'c6',
    image: 'c6.jpg',
    selected: false,
    link: 'assets/imgs/covers/c6.jpg'
  }, {
    id: 'c7',
    image: 'c7.jpg',
    selected: false,
    link: 'assets/imgs/covers/c7.jpg'
  }, {
    id: 'c8',
    image: 'c8.jpg',
    selected: false,
    link: 'assets/imgs/covers/c8.jpg'
  }];

  private suits = [{
    selected: true,
    acronym: 'CHU',
    name: 'Ciências Humanas',
    img: '/assets/imgs/disciplines/ch_big.svg'
  }, {
    selected: true,
    acronym: 'MAT',
    name: 'Matemática',
    img: '/assets/imgs/disciplines/ce_big.svg'
  }, {
    selected: true,
    acronym: 'LEC',
    name: 'Linguagens e Códigos',
    img: '/assets/imgs/disciplines/lc_big.svg'
  }, {
    selected: true,
    acronym: 'CDN',
    name: 'Ciências da Natureza',
    img: 'assets/imgs/disciplines/cn_big.svg'
  }];

  /** Avatar list*/

  constructor(
    public alertCtrl: AlertController,
    public appRate: AppRate,
    private fb: Facebook,
    public firebase: FirebaseX,
    public platform: Platform,
    public services: ServicesService,
    private storage: StorageService
  ) {
    // this.FB_API_VERSION = 'v6.0';
    if (this.platform.is('mobile')) {
      this.appRate.setPreferences({
        usesUntilPrompt: 3,
        storeAppURL: { ios: '1165150692', android: 'market://details?id=br.com.enemgame' }
      });
    }
    // TODO testar
    this.appRate.promptForRating(true);
  }

  public get disciplines() {
    return this.suits;
  }

  public getAvatar(avatar, facebookId) {
    if (!avatar || (avatar === 'facebook' && !facebookId)) { return 'assets/imgs/avatar.jpg'; }
    else {
      if (avatar.startsWith('http')) { return avatar; }
      else if (avatar === 'facebook') { return `https://graph.facebook.com/${facebookId}/picture?type=square&width=512&height=512`; }
      else { return `${this.services.getStaticUrl()}avatars/md/${avatar}`; }
    }
  }

  //todo
  public getCover(cover, facebookId) {
    if ((!cover && !facebookId) || (cover === 'facebook' && !facebookId)) { return 'assets/imgs/covers/c1.jpg'; }
    else if (!cover && facebookId) {
      // return this.setFacebookCoverImage(facebookId);
    }
    else {
      if (cover.startsWith('http')) { return cover; }
      // else if (cover === 'facebook') {return this.setFacebookCoverImage(facebookId);}
      else { return 'assets/imgs/covers/' + cover + '.jpg'; }
    }
  }

  setFacebookCoverImage(facebookId) {
    let facebookCoverImage;
    this.fb.getLoginStatus().then(res => {
      this.fb.api('/' + this.FB_API_VERSION + '/' + facebookId + '/?fields=cover',
        ['email', 'public_profile', 'user_friends']).then(_res => {
          console.log('_res: ', _res);
          if (_res.cover) { return facebookCoverImage = _res.cover.source; } else { return facebookCoverImage = 'assets/imgs/c1.jpg'; }
        }).catch(err => {
          console.error('Error on facebook cover get: ', err);
          return facebookCoverImage = 'assets/imgs/c1.jpg';
        });
    }).catch(err => {
      console.error('error on login status: ', err);
      return facebookCoverImage = 'assets/imgs/c1.jpg';
    });
  }

  resetPassword(email): Promise<void> {
    return this.services.post('auth/forgot-password', { data: { email } });
  }

  public async showFeedbackAlert(title, message) {
    await this.alertCtrl.create({
      header: title,
      message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    }).then(alert => alert.present());
  }

  /**GET AVATAR LIST*/
  getAvatarsFromServer(user) {
    const success = (res) => {
      console.log('getAvatarsFromServer res: ', res);
      this.avatars.concat(res.data);
      if (user.facebookId) {
        this.avatars.unshift({
          id: 'facebook',
          image: 'facebook',
          selected: false,
          link: 'https://graph.facebook.com/' +
            user.facebookId +
            `/picture?type=square&width=${Number.MAX_SAFE_INTEGER}&height=${Number.MAX_SAFE_INTEGER}`
        });
      }
      if (user.googleplus) {
        this.avatars.unshift({
          id: 'googleplus',
          image: user.googleplus.imageUrl,
          selected: false,
          link: user.googleplus.imageUrl
        });
      }
      this.avatars.forEach(avatar => {
        user.profile.avatar === avatar.image ? avatar.selected = true : avatar.selected = false;
      });
    };
    const error = (err) => console.error('Error on avatars get: ', err);
    this.services.get('avatars', { success, error });
  }

  getCovers(user) {
    if (user.facebookId && user.getUserInfo().facebookCoverImage) {
      this.covers.unshift({
        id: 'facebook',
        image: 'facebook',
        selected: false,
        link: user.getUserInfo().facebookCoverImage
      });
    }
    this.covers.forEach(cover => {
      if (user.profile.coverImage) { user.profile.coverImage === cover.id ? cover.selected = true : cover.selected = false; }
      else if (user.facebookId && user.facebookCoverImage && cover.id === 'facebook') { cover.selected = true; }
      else if (cover.id === 'c4') {
        cover.selected = true;
        user.profile.coverImage = 'c4';
      }
    });
  }

  public rateApp() {
    console.log('rateApp');
    if (this.platform.is('mobile')) {
      console.log('promptForRating');
      this.appRate.promptForRating(false);
    }
  }

  public openAppStore() {
    console.log('Open App Store');
    this.appRate.navigateToAppStore();
  }

  verifyNotificationState() {
    this.notificationOff = false;
    this.notificationToggle = !this.notificationOff;
    this.storage.get('notificationOff').then((notificationOff) => {
      if (notificationOff) {
        this.notificationOff = notificationOff;
        this.notificationToggle = !this.notificationOff;
      } else { this.fcmInit(); }
    }).catch(err => this.fcmInit());
    this.notificationStateVerified = true;
  }

  fcmInit() {
    console.info('Subscribe to general topic');
    if (this.firebase) {
      this.firebase.subscribe('general');
      this.firebase.onMessageReceived()
        .subscribe(data => console.log(`User opened a notification ${data}`));

    }
  }

  fcmOff() {
    console.info('Unsubscribe from general topic');
    if (this.firebase) { this.firebase.unsubscribe('general'); }
  }

  changeNotificationState() {
    if (this.notificationStateVerified) {
      this.notificationOff = !this.notificationOff;
      if (this.notificationOff) { this.fcmOff(); } else { this.fcmInit(); }
      this.storage.set('notificationOff', this.notificationOff).catch(err => console.error('Error on notification storage set: ', err));
    }
  }
}
