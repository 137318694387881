import { AdMob, InterstitialAd, BannerAd } from 'community-admob-plus-cordova';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public TIME_TO_ANOTHER_AD = 600000;
  public bannerIsReady: boolean;
  public interstitialIsReady: boolean;
  public rewardVideoIsReady: boolean;
  public lastInterstitialTime: number;
  public videoPresented: boolean;
  public videoDismissed: boolean;
  public banner: BannerAd;
  public interstitial: InterstitialAd;
  public rewarded;

  private currentBanner: any;
  private adId;

  constructor(
    public admob: AdMob,
    public http: HttpClient,
    private platform: Platform,
    private router: Router,
    private navCtrl: NavController) {
    this.adsIds();
  }

  //GETTERS
  //Ads Options

  get leaderboardBannerOptions() {
    console.log('leaderboardBannerOptions');

    return {
      adUnitId: this.adId.banner,
      adSize: 'SMART_BANNER',
      overlap: true,
      autoShow: false,
      position: 'top',
      // position: this.admob.AD_POSITION.TOP_CENTER
    };
  }

  get mediumRectangleBannerOptions() {
    return {
      adUnitId: this.adId.inlineRectangleBanner,
      position: 'bottom',
      autoShow: false,
      overlap: false,
      offsetTopBar: false,
      adSize: 'MEDIUM_RECTANGLE'
    };
  }

  get interstitialOptions() {
    return {
      adUnitId: this.adId.interstitial,
      autoShow: false,
    };
  }

  get rewardVideoOptions() {
    return {
      adUnitId: this.adId.rewardvideo,
      autoShow: false,
      isTesting: false
    };
  }

  get timeToShowAd() {
    if (!this.lastInterstitialTime) {
      return true;
    } else { return ((new Date()).getTime() - this.lastInterstitialTime > this.TIME_TO_ANOTHER_AD); }
  }
  //DECLARATIONS
  async adsIds() {
    console.log('adsIds');
    this.platform.ready().then(async () => {
      await this.admob.start();
    });
    this.adId = {};
    if (this.platform.is('android')) {
      this.adId = {
        banner: 'ca-app-pub-3565375993635514/7290393439',
        rewardvideo: 'ca-app-pub-3565375993635514/2964135618',
        interstitial: 'ca-app-pub-3565375993635514/8000656711',
        inlineRectangleBanner: 'ca-app-pub-3565375993635514/9555328226'
      };
    } else if (this.platform.is('ios')) {
      this.adId = {
        banner: 'ca-app-pub-3565375993635514/8127032573',
        rewardvideo: 'ca-app-pub-3565375993635514/2926676789',
        interstitial: 'ca-app-pub-3565375993635514/3155655968',
        inlineRectangleBanner: 'ca-app-pub-3565375993635514/6304563480'
      };
    }
  }

  // PREPARE METHODS
  async prepareInterstitial() {
    if (!this.platform.is('cordova')) { return; }
    this.interstitial = new this.admob.InterstitialAd(this.interstitialOptions);
    this.interstitial.on('loadfail', (evt: any) => this.interstitialIsReady = false);
    this.interstitial.on('load', (evt: any) => this.interstitialIsReady = true);
    this.interstitial.on('show', (evt: any) => {
      this.interstitialIsReady = true;
      this.lastInterstitialTime = new Date().getTime();
    });
    this.interstitial.on('dismiss', async (evt: any) => {
      this.interstitialIsReady = false;
      // await this.interstitial.load();
      // TODO reimplementar
      if (this.router.url === 'SinglePlayerGameResultsPage') {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.navCtrl.navigateRoot('tabs/home', { queryParams: { GameMode: 'GameMode' } });
      }
    });
    await this.interstitial.load();
  }

  //options: of the specific banner
  //type: 'leaderboard' or 'medium rectangle'
  async prepareBanner(options: {
    adUnitId: any;
    adSize: string;
    overlap: boolean;
    autoShow: boolean;
    position: any;
    offsetTopBar?: boolean;
  }, type: string) {
    if (!this.platform.is('cordova')) { return; }
    if (!this.currentBanner || (this.currentBanner !== type)) {
      // TODO reimplementar Admob
      this.banner = new this.admob.BannerAd(options);
      if (this.currentBanner && type === 'leaderboard' && this.currentBanner !== type) {
        // if (this.admob &&  this.platform.is('mobile')) document.getElementsByTagName("body")[0].classList.add("adBody");
        this.banner.on('loadfail', async (evt: any) => {
          console.error('banner loadfail: ', evt);
          this.bannerIsReady = false;
          await this.banner.hide();
        });
        this.banner.on('load', async (evt: any) => { this.bannerIsReady = true; });
        await this.banner.show();
      }
      this.currentBanner = type;
    }
  }

  async prepareRewardVideo() {
    console.log('prepareRewardVideo');
    // this.rewarded = new this.admob.RewardedAd(this.rewardVideoOptions)
    this.rewarded.on('loadfail', (evt: any) => {
      // console.error("rewarded loadfail: ", evt)
      this.rewardVideoIsReady = false;
    });
    this.rewarded.on('load', async (evt: any) => {
      // console.error("rewarded load: ", evt)
      this.rewardVideoIsReady = true;
      this.videoPresented = false;
    });
    this.rewarded.on('show', async (evt: any) => {
      // console.error("rewarded show: ", evt)
      this.rewardVideoIsReady = false;
      this.lastInterstitialTime = new Date().getTime();
      this.videoPresented = true;
    });
    this.rewarded.on('dismiss', async (evt: any) => {
      // console.error("rewarded dismiss: ", evt)
      this.videoDismissed = true;
      await this.rewarded.load();
    });
    await this.rewarded.load();
  }

}
