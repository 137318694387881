import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ProfilePopoverPage } from './profile-popover';

@NgModule({
  declarations: [
    ProfilePopoverPage,
  ],
  imports: [
    RouterModule,
    CommonModule,
    IonicModule.forRoot(),
  ],
})
export class ProfilePopoverPageModule {}
