import { Component } from '@angular/core';
import { NavController, NavParams, Platform } from '@ionic/angular';

@Component({
  selector: 'page-achievement-card-modal',
  templateUrl: 'achievement-card-modal.html',
  styleUrls: ['achievement-card-modal.scss']
})
export class AchievementCardModalPage {
  public achievement: any;
  public imageSrc: string;
  public total: number;
  private unregisterBackButton;

  constructor(public navCtrl: NavController, public navParams: NavParams, public platform: Platform) {
    this.achievement = this.navParams.get("achievement");
    this.imageSrc = this.navParams.get("imageSrc");
    this.total = ["bronze", "silver", "gold"].reduce((acc, level) => acc + this.achievement[level].current, 0);
    // TODO Analisar alternativa pós migração ionic 3 para 4
    // this.unregisterBackButton = this.platform.registerBackButtonAction(()=>this.viewCtrl.dismiss(), 10);
  }

  public ionViewWillLeave() {
    // this.unregisterBackButton();
  }

  round(time) {
    return Math.round(time);
  }

  public calculateTime(time) {
    time *= 1000; // time in milliseconds
    let day = 1000 * 60 * 60 * 24; // number of milliseconds in a day
    let list = [];
    let days = time / day >> 0; // days played
    if (days) list.push(`${days} dia${days > 1 ? "s" : ""}`);
    time = time % day;
    let date = new Date(time);
    let hours = date.getUTCHours();
    if (hours) list.push(`${hours} hora${hours > 1 ? "s" : ""}`);
    let minutes = date.getUTCMinutes();
    if (minutes) list.push(`${minutes} minuto${minutes > 1 ? "s" : ""}`);
    let seconds = date.getUTCSeconds();
    if (seconds) list.push(`${seconds} segundo${seconds > 1 ? "s" : ""}`);
    return list.join(", ");
  }
}
