/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { Platform } from '@ionic/angular';
import { StorageService } from './storage.service';

declare let cordova: any;

interface Sound {
  id: string;
  asset: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class AudioService {

  /* Sound Ids Consts */
  SFX_ID = {
    BACK: 'back',
    CLICK: 'popover-objective-pop',
    CONTRACT: 'contract',
    CORRECT: 'correct',
    EXPAND: 'expand',
    GAMEOVER: 'gameover',
    INCORRECT: 'incorrect',
    PLAY: 'play',
    SELECT: 'select',
    SLIDEIN: 'slidein',
    LEVEL_UP: 'levelup',
    PROGRESS_XP: 'xp-objective-progress',
    POPOVER_PRESENT: 'popover-objective-pop',
    PROGRESS_GOLD: 'gold-redeem'
  };

  MUSIC_ID: any = {
    MATCH: 'match',
    MENU: 'menu',
  };

  type: 'native' | 'html5';
  sounds: Sound[] = [];
  muteFX: boolean;
  muteMusic: boolean;
  soundStateVerified: boolean;
  inGame: boolean;
  toggleMusic: boolean;
  toggleFX: boolean;
  currentMusic: HTMLAudioElement;
  lastLoop: string;
  private _looping: string;

  constructor(public http: HttpClient,
    private platform: Platform,
    public nativeAudio: NativeAudio,
    private storage: StorageService
  ) {
    this.platform.is('cordova') ? this.type = 'native' : this.type = 'html5';
  }

  public get looping(): string {
    return this._looping;
  }

  public set looping(value: string) {
    this._looping = value;

    if (value != null) {
      this.lastLoop = value;
    }
  }

  verifySoundState() {
    console.log('verifySoundState');
    this.muteFX = false;
    this.muteMusic = false;
    this.toggleFX = !this.muteFX;
    this.toggleMusic = !this.muteMusic;
    this.storage.get('muteFX').then((mute) => {
      if (mute) {
        this.muteFX = mute;
        this.toggleFX = !this.muteFX;
      }
    }).catch(err => console.error('Error on mute fx storage get: ', err));
    this.storage.get('muteMusic').then((mute) => {
      if (mute) {
        // console.log('Pegou no mute');
        this.muteMusic = mute;
        this.toggleMusic = !this.muteMusic;
      }
    }).catch(err => console.error('Error on mute fx storage get: ', err));
    this.soundStateVerified = true;
  }

  play(id: string) {
    const audio = this.sounds.find(sound => sound.id === id);
    if (!this.muteFX && audio) {
      if (audio.type === 'native') {
        this.nativeAudio.play(audio.asset).catch((err) => console.error('Audio play error:', err));
      } else {
        const audioAsset = new Audio(audio.asset);
        audioAsset.play();
      }
    }
  }

  async loop(id: string) {
    if (this.looping && this.looping !== id) { await this.stop(this.looping); }
    if (this.looping === id) { return; }
    const audio = this.sounds.find(sound => sound.id === id);
    if (!this.muteMusic && audio) {
      console.log('Plataforma atual no audio.type:', audio.type);
      console.log('O que tem no audio.asset', audio.asset);
      console.log('IDDDDDDDDD', id);

      if (audio.type === 'native') {
        this.nativeAudio
          .loop(audio.asset)
          .then(() => (this.looping = id))
          .catch((err) => console.error('Audio loop error:', err));
      } else {
        if (!this.currentMusic) {
          this.currentMusic = new Audio(audio.asset);
        }
        // this.currentMusic.muted = false;
        this.currentMusic
          .play()
          .then(() => {
            this.looping = id;
          })
          .catch((err) => {
            console.error('Audio loop error:', err);
            this.startLoopAfterClick(id);
          });
      }
    }
  }

  playAgain() {
    this.inGame ? this.loop(this.MUSIC_ID.MATCH) : this.loop(this.MUSIC_ID.MENU);
  }

  async stopLoop() {
    const audio = this.sounds.find((sound) => sound.id === this.looping);

    if (audio && audio.type === 'native') {
      this.looping = null;
      await this.nativeAudio
        .stop(audio.asset)
        .catch((err) => console.error('Audio loop stop error:', err));
    } else {
      if (this.currentMusic) {
        this.currentMusic.pause();
        this.currentMusic.currentTime = 0;
      }
    }
  }

  async stop(id: string) {
    const audio = this.sounds.find(sound => sound.id === id);

    if (audio && audio.type === 'native') {
      this.looping = null;
      await this.nativeAudio.stop(audio.asset)
        .then(() => this.nativeAudio.unload(audio.asset))
        .then(() => this.loadApplicationMusics())
        .catch(err => console.error('Audio stop error:', err));
    } else {
      if (this.currentMusic) {
        this.currentMusic.pause();
        this.currentMusic.currentTime = 0;
      }
    }
  }

  stopAll() {
    for (const value of Object.values(this.MUSIC_ID)) {
      this.stop(String(value));
    }
  }

  async restartLastLoopAudio(): Promise<void> {
    return this.loop(this.lastLoop);
  }

  loadApplicationSounds() {
    this.loadApplicationSFX();
    this.loadApplicationMusics();
  }

  async loadApplicationSFX() {
    for await (const value of Object.values(this.SFX_ID)) {
      this.preload(String(value), 'assets/audio/fx/' + value + '.mp3');
    }
  }

  async loadApplicationMusics() {
    for await (const value of Object.values(this.MUSIC_ID)) {
      this.preloadComplex(String(value), 'assets/audio/music/' + value + '.mp3', 1, 1, 0);
    }
  }

  changeMuteFXState() {
    // console.log('Nem ta');
    if (this.soundStateVerified) {
      this.muteFX = !this.muteFX;
      // console.info('FX mute state changed: ', this.muteFX);
      this.storage.set('muteFX', this.muteFX).catch(err => console.error('Error on muteFX storage set'));
    }
  }

  async changeMuteMusicState() {
    if (this.soundStateVerified) {
      console.log('Entrou no if soundStateVerified', this.soundStateVerified);

      this.muteMusic = !this.muteMusic;
      console.log('Novo valor de muteMusic', this.muteMusic);

      this.muteMusic ? this.stopAll() : this.playAgain();
      await this.storage.set('muteMusic', this.muteMusic).catch(err => console.error('Error on muteMusic storage set'));

      const teste = await this.storage.get('muteMusic');
      console.log('Valor recuperado do storage', teste);
    }
    console.log('Caiu caindo fora do if', this.soundStateVerified);
  }

  startLoopAfterClick(id: string): void {
    const loopStart = () => {
      this.currentMusic
        .play()
        .then(() => (this.looping = id))
        .catch((err) => console.error('Audio loop catch: ', err));
      document.removeEventListener('click', loopStart);
    };

    document.addEventListener('click', loopStart);
  }

  private preload(id: string, assetPath: string): void {
    if (this.sounds.findIndex(s => s.id === id) === -1) {
      if (this.type === 'native') {
        this.nativeAudio.preloadSimple(id, assetPath)
          .catch(err => {
            console.log(assetPath);
            // console.log(this.sounds.findIndex(s => s.id === id) === -1);
            console.error('Error preloading audio: ', err);
          });
      }

      const audio: Sound = {
        id,
        asset: this.type === 'native' ? id : assetPath,
        type: this.type
      };
      this.sounds.push(audio);
    }
  }

  private preloadComplex(
    id: string,
    assetPath: string,
    volume: number,
    voices: number,
    delay: number
  ): void {
    if (this.sounds.findIndex(s => s.id === id) === -1) {
      if (this.type === 'native') {
        this.nativeAudio.preloadComplex(id, assetPath, volume, voices, delay)
          .catch(err => console.error('Error preloading complex audio: ', err));
      }
      const audio = { id, asset: this.type === 'native' ? id : assetPath, type: this.type };
      this.sounds.push(audio);
    }
  }
}
