import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServicesService } from './services.service';

@Injectable({
  providedIn: 'root'
})
export class AchievementsService {

  public achievementsList: any[];
  public bronzeTotal = 0;
  public silverTotal = 0;
  public goldTotal = 0;

  constructor(private services: ServicesService, public http: HttpClient) {
  }

  clearService() {
    this.achievementsList = [];
    this.bronzeTotal = 0;
    this.silverTotal = 0;
    this.goldTotal = 0;
  }

  getAchievements(force: boolean = false) {
    if (!force && this.achievementsList && this.achievementsList.length)
      {return Promise.resolve(this.achievementsList);}
    this.bronzeTotal = 0;
    this.silverTotal = 0;
    this.goldTotal = 0;
    this.achievementsList = [];
    return this.services.get('achievements/').then(res => {
      console.info('sucesso ao pegar achievements');
      this.achievementsList = res.data.filter(a =>
        a.name !== 'Vitórias' ? true : false
      );
      console.log('this.achievementsList', this.achievementsList);
      this.achievementsList.forEach(achievement => {
        if (achievement.tier === 'bronze') {this.bronzeTotal++;}
        else if (achievement.tier === 'silver') {this.silverTotal++;}
        else if (achievement.tier === 'gold') {this.goldTotal++;}
      });
      return this.achievementsList;
    });
    /*, {
        success: res => {
            console.log("sucesso ao pegar achievements");
            this.achievementsList = res.data;
            this.achievementsList.forEach(achievement => {
                if (achievement.tier === "bronze") this.bronzeTotal++;
                else if (achievement.tier === "silver") this.silverTotal++;
                else if (achievement.tier === "gold") this.goldTotal++;
            });
        },
        error: console.error
    });*/
  }

  setAchievementScore(achievementName, toIncrease) {
    this.achievementsList.forEach((achievement) => {
      if (achievement.name === achievementName) {
        switch (achievement.tier) {
          case 'bronze':
            this.calculateCurrentAchievement(toIncrease, achievement, achievement.silver);
            break;
          case 'silver':
            achievement.gold.current += toIncrease;
            if (achievement.gold.current > achievement.gold.max) {
              achievement.gold.current = achievement.gold.max;
              console.info('Achievement completo');
              achievement.tier = 'gold';
            }
            break;
          case 'gold':
            console.info('Este achievement está completo');
            break;
          default:
            this.calculateCurrentAchievement(toIncrease, achievement, achievement.bronze);
            break;
        }
      }
    });
  }

  getAchievementTotalScore(achievement) {
    return ['bronze', 'silver', 'gold'].reduce((acc, level) => acc + achievement[level].current, 0);
  }

  calculateCurrentAchievement(score, achievement, currentAchievement) {
    currentAchievement.current += score;
    if (currentAchievement.current > currentAchievement.max) {
      const newScore = (currentAchievement.current - currentAchievement.max);
      currentAchievement.current = currentAchievement.max;
      achievement.tier === 'bronze' ? achievement.tier = 'silver' : achievement.tier = 'bronze';
      this.setAchievementScore(achievement.name, newScore);
    }
  }

  public getClass(achievementName) {
    switch (achievementName) {
      case 'Matemática':
      case 'Dias Logados':
        return 'liteblue-bg';
      case 'Ciências da Natureza':
      case 'XP':
        return 'green-bg';
      case 'Ciências Humanas':
      case 'Tempo de Jogo':
        return 'magenta-bg';
      case 'Linguagens e Códigos':
      case 'Vitórias':
        return 'gold-bg';
    }
  }

  public getColor(achievementName) {
    switch (achievementName) {
      case 'Matemática':
      case 'Dias Logados':
        return '#4A76B4';
      case 'Ciências da Natureza':
      case 'XP':
        return '#679E4F';
      case 'Ciências Humanas':
      case 'Tempo de Jogo':
        return '#872461';
      case 'Linguagens e Códigos':
      case 'Vitórias':
        return '#FEBD10';
    }
  }

  public getColorClass(achievementName) {
    switch (achievementName) {
      case 'Matemática':
      case 'Dias Logados':
        return 'liteblue';
      case 'Ciências da Natureza':
      case 'XP':
        return 'green';
      case 'Ciências Humanas':
      case 'Tempo de Jogo':
        return 'magenta';
      case 'Linguagens e Códigos':
      case 'Vitórias':
        return 'gold';
    }
  }

  public getImageSrc(achievementName) {
    let src;
    switch (achievementName) {
      case 'Matemática':
        src = 'achievements/matematica';
        break;
      case 'Ciências da Natureza':
        src = 'achievements/ciencias-natureza';
        break;
      case 'Ciências Humanas':
        src = 'achievements/ciencias-humanas';
        break;
      case 'Linguagens e Códigos':
        src = 'achievements/linguagens-codigos';
        break;
      case 'Dias Logados':
        src = 'achievements/days-logged-in';
        break;
      case 'XP':
        src = 'achievements/xp-earned';
        break;
      case 'Tempo de Jogo':
        src = 'achievements/questionTime-played';
        break;
      case 'Vitórias':
        src = 'achievements/multiplayer-wins';
        break;
    }
    return `assets/imgs/${src}.svg`;
  }
}
