import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AnimationController, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { Match } from '../../models/Match';
import { Question } from '../../models/Question';
import { AdsService } from 'src/app/services/ads.service';
import { AudioService } from 'src/app/services/audio.service';
import { EconomyService } from 'src/app/services/economy.service';
// import { ObjectivesService } from "src/app/services/objectives.service";
import { QuestionService } from 'src/app/services/question.service';
import { ServicesService } from 'src/app/services/services.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';
import { SinglePlayerIntervalPage } from '../single-player-interval/single-player-interval.page';
import { ConfigModalPage } from '../../modals/config-modal/config-modal';
import { PrimeService } from 'src/app/services/prime.service';
import { PrimeFinancialRenewPage } from 'src/app/modals/prime-financial-renew/prime-financial-renew';

@Injectable()
export class SinglePlayerGameplay {

  /**TOOLS CONSTS*/
  public SKIP_TOOL = 'skipTool';
  public ANSWER_TWO_TOOL = 'answerTwoTool';
  public ELIMINATE_TOOL = 'eliminateTool';

  /**how many alternatives will be eliminated*/
  public TO_ELIMINATE = 2;

  /**GAMEPLAY INFO*/
  public oldRecord;
  public isDateValid: boolean;
  openedWindow: Window;
  public numberPositions: { top: string; left: string }[] = [
    { top: '-5px', left: '10px' }, { top: '22px', left: '35px' }, { top: '39px', left: '74px' }, { top: '48px', left: '122px' },
    { top: '48px', left: '173px' }, { top: '39px', left: '221px' }, { top: '22px', left: '260px' }, { top: '-5px', left: '283px' }];
  public numberSmallDevicePositions: { top: string; left: string }[] = [
    { top: '-18px', left: '-8px' }, { top: '9px', left: '16px' }, { top: '31px', left: '53px' }, { top: '44px', left: '103px' },
    { top: '44px', left: '157px' }, { top: '33px', left: '209px' }, { top: '9px', left: '252px' }, { top: '-14px', left: '245px' }];
  private correctQuestionAnswer;

  /**CLASS CONSTS*/
  private ANSWER = 'answer';

  /**CLASS*/
  private question;
  private match;


  constructor(public prime: PrimeService, private ads: AdsService, private audio: AudioService, private economy: EconomyService,
    public modalCtrl: ModalController,/*  private objectives: ObjectivesService, */ private questionP: QuestionService,
    private services: ServicesService, public toastCtrl: ToastController, private user: UserService,
    private util: UtilService, private animationCtrl: AnimationController, public popoverCtrl: PopoverController,
    private platform: Platform) {
    if (!this.match) { this.newMatch(); }
  }

  /**GETTERS AND SETTERS*/
  getQuestion() {
    return this.question;
  }

  /**METHODS*/

  /**QUESTION METHODS*/

  /**ANSWER*/
  answer(event, alternative, acronym) {
    this.lastQuestion(acronym, alternative);
    /**class matchToAnalytics*/
    this.match.getMatchAnalytics().addAnswer(alternative.id, alternative.isCorrect);

    this.question.setAlternativeSelected(alternative.isSelected = true);
    this.question.answerQuantity--;
  }

  selectAlternative(event, alternative, acronym) {
    this.question.setAlternativeSelected(alternative.isSelected = true);
    this.match.getMatchAnalytics().addAnswer(alternative.id, alternative.isCorrect);
    this.question.answerQuantity--;
    if (this.question.answerTwoSelectedAlternatives.length === 0) {
      this.question.answerTwoSelectedAlternatives.push(alternative);
    } else {
      this.question.answerTwoSelectedAlternatives.push(alternative);
      this.lastQuestion(acronym, alternative);
    }
  }

  async lastQuestion(acronym, alternative) {
    /**class matchToAnalytics*/
    console.log('lastQuestion');
    this.match.getMatchAnalytics().addAction(this.ANSWER);
    // this.objectives.updateObjetiveValue('answer-question', 1);
    // this.objectives.updateObjectivePerSuit(acronym, 1);
    /**Increase Achievements Values*/
    this.increaseAchievementsValues(acronym);
    /**Clear question timer and update quick answer score*/
    clearInterval(this.question.timer);
    /**QUESTION ANSWER CORRECT OR INCORRECT*/
    if (this.question.answerTwo) {
      this.question.answerTwoSelectedAlternatives.forEach((alternativeOfTwo) => {
        if (alternativeOfTwo.isCorrect) {
          this.correctQuestionAnswer = true;
        }
      });
    } else {
      if (alternative.isCorrect) {
        this.correctQuestionAnswer = true;
      } else {
        this.correctQuestionAnswer = false;
      }
    }
    // window.setTimeout(() => {
    //   this.questionResult();
    // }, 3000);
    await new Promise(resolve => setTimeout(resolve, 3000));
    this.questionResult();
    // this.cdr.detectChanges();
  }

  correctAnswer() {
    console.log('isCorrect');
    /** Sound Effect*/
    this.audio.play(this.audio.SFX_ID.CORRECT);
    /**Firebase event*/
    if (this.util.firebase) { this.util.firebase.logEvent('single_player_gameplay', { question_answered: 'game_question_correct' }); }

    this.match.updateScore(this.match.CORRECT_SCORE.TYPE);
    // this.objectives.updateObjetiveValue('correct-question', 1);
    if (this.match.questionNumber % 5 === 0 && this.match.questionNumber > 0) {
      this.match.updateScore(this.match.BONUS_QUESTION_SCORE.TYPE);
    }
    if (this.question.questionTime <= 180) {
      this.match.updateScore(this.match.QUICK_ANSWER_SCORE.TYPE);
    }

  }

  incorrectAnswer() {
    console.log('isIncorrect');
    /**Firebase event*/
    if (this.util.firebase) { this.util.firebase.logEvent('single_player_gameplay', { question_answered: 'game_question_wrong' }); }
    this.match.life--;
    this.match.hearts[this.match.life] = 'death';
    /** Sound Effect*/
    this.match.life > 0 ? this.audio.play(this.audio.SFX_ID.INCORRECT) : this.audio.play(this.audio.SFX_ID.GAMEOVER);
    /**Match End*/
    if (this.match.life <= 0 && (!this.match.oneMoreChanceWithVideo && !this.match.oneMoreChanceWithED)) {
      // this.match.end();
    } else if (this.match.life === 1 && this.platform.is('cordova') && this.question.questionP.questionIndex % 3 === 0) {
      this.ads.prepareRewardVideo();
      if (this.ads.timeToShowAd && (this.match.oneMoreChanceWithVideo && this.match.oneMoreChanceWithED)) {
        this.ads.prepareInterstitial();
      }
    }
  }

  async questionResult() {
    /**Clean data question*/
    if (this.question.answerQuantity <= 0) {
      this.question.setQuestionAnswered(true);
      /**Update answer score*/
      if (this.correctQuestionAnswer) {
        this.correctAnswer();
      }
      if (!this.correctQuestionAnswer) {
        this.incorrectAnswer();
      }

      if (this.match.life > 0 || (
        this.match.oneMoreChanceWithVideo || (
          this.match.oneMoreChanceWithED && this.user.getUserInfo().enemDolar >= this.economy.continueMatch.price))) {

        if (this.match.life > 0) {
          if (this.question.questionP.questionIndex % 3 === 0) {
            this.presentIntervalModal();
            await new Promise(resolve => setTimeout(resolve, 3500));
          } else {
            this.nextQuestion();
          }
        }

        await new Promise(resolve => setTimeout(resolve, 4000));
        this.questionP.newQuestion(this.match.getMatchAnalytics(), this.match.questionNumber);
        this.question = new Question(this.questionP);
        this.question.questionTimer();
      }
    }
  }

  async presentIntervalModal() {
    this.isDateValid = await this.prime.handleAccessDateValidation();
    const loginUrl = await this.prime.loginGetURL();

    if (!(!!this.user.prime_email)) {
      if (this.platform.is('cordova')) {
        await this.ads.prepareInterstitial();
        await this.ads.interstitial.show();
      }
      else {
        const intervalModal = await this.popoverCtrl.create({
          component: SinglePlayerIntervalPage,
          cssClass: 'big-popover',
          backdropDismiss: false
        });
        await intervalModal.present();
      }
    }

    if ((this.user.prime_email && !this.isDateValid)) {
      if (this.platform.is('cordova')) {
        await this.ads.prepareInterstitial();
        await this.ads.interstitial.show();
      }
      else {
        await this.popoverCtrl.create({
          component: PrimeFinancialRenewPage,
          componentProps: { musicID: this.audio.MUSIC_ID.MENU },
          cssClass: 'small-popover',
          backdropDismiss: true

        }).then(popover => popover.present());
      }
    }
  }

  /**ACHIEVEMENTS METHODS*/
  increaseAchievementsValues(acronym) {
    Object.values(this.match.achievements).forEach((achievement: any) => {
      if (achievement.acronym && achievement.acronym === acronym) {
        achievement.toIncrease++;
      }
    });
  }

  nextQuestion() {
    this.match.updateScore(this.match.QUESTION_SEEN_SCORE.TYPE);
    const questionInterval = setInterval(() => {
      if (this.questionP.questionPrepared && this.match.getMatchAnalytics().ready) {
        this.moveBar();
        this.questionP.questionReady = true;
        this.question.questionTimer();
        this.audio.play(this.audio.SFX_ID.SLIDEIN);
        //this.match.questionNumber++;
        if (this.match.questionNumber >= this.match.record) { this.user.updateSinglePlayerRecord(this.match.questionNumber); }
        clearInterval(questionInterval);
        this.questionP.questionPrepared = false;
        this.correctQuestionAnswer = false;
      }
    }, 1000);
    this.match.getMatchAnalytics().addAction('next-question');
  }

  /**MOVE PROGRESS BAR*/

  // public moveBar() {
  //     this.match.progressMarginNum += -72;
  //     this.match.progressMargin = this.match.progressMarginNum + 'px';
  //     if (this.match.questionNumber > 4) this.match.progressNumbers.push(this.match.questionNumber + 9);
  // }

  public async moveBar() {

    if (this.match.questionNumber >= 4) {
      const animations = [];

      const anim = this.animationCtrl.create()
        .addElement(document.getElementById('number-position4'))
        .duration(700)
        .fill('none')
        .keyframes([
          { background: 'linear-gradient(150deg, #F493B0 0%, #D82298 55%, #9613a4 85%)', border: '1px solid #98373C', color: 'white' },
          {
            background: 'linear-gradient(150deg, #86eae9 0%, #0812DD 65%, #0812DD 75%, white 100%)',
            border: '1px solid #000066',
            color: 'white'
          }
        ]);

      if (this.platform.width() < 300) {
        for (let index = 1; index < this.numberSmallDevicePositions.length; index++) {
          const anim1 = this.animationCtrl.create()
            .addElement(document.getElementById('number-position' + index))
            .fill('none')
            .keyframes([
              { left: this.numberSmallDevicePositions[index].left, top: this.numberSmallDevicePositions[index].top },
              { left: this.numberSmallDevicePositions[index - 1].left, top: this.numberSmallDevicePositions[index - 1].top }
            ]);
          animations.push(anim1);
        }
      } else {
        for (let index = 1; index < this.numberPositions.length; index++) {
          const anim1 = this.animationCtrl.create()
            .addElement(document.getElementById('number-position' + index))
            .fill('none')
            .keyframes([
              { left: this.numberPositions[index].left, top: this.numberPositions[index].top },
              { left: this.numberPositions[index - 1].left, top: this.numberPositions[index - 1].top }
            ]);
          animations.push(anim1);
        }
      }

      const parent = this.animationCtrl.create()
        .duration(700)
        .addAnimation(animations);

      anim.play();
      await parent.play();
      this.match.questionNumber++;
      const anim2 = this.animationCtrl.create()
        .addElement(document.getElementById('number-position4'))
        .duration(700)
        .keyframes([
          {
            background: 'linear-gradient(150deg, #fff0a7 0%, #F4AC36 35%, #DB8105 65%, #8b520e 100%)',
            border: '1px solid #CC7205',
            color: '#B13B06'
          },
          { background: 'linear-gradient(150deg, #F493B0 0%, #D82298 55%, #9613a4 85%)', border: '1px solid #98373C', color: 'white' }
        ]);
      await anim2.play();
    }
    else {
      // const anim = this.animationCtrl.create()
      //     .addElement(document.getElementById("avatar"))
      //     .duration(700)
      //     .keyframes([
      //         {left : this.numberPositions[this.match.questionNumber].left, top : this.numberPositions[this.match.questionNumber].top},
      //         {left : this.numberPositions[this.match.questionNumber+1].left,
      //  top : this.numberPositions[this.match.questionNumber+1].top}
      //     ]);
      const anim = this.animationCtrl.create()
        .addElement(document.getElementById('number-position' + this.match.questionNumber))
        .duration(700)
        .keyframes([
          { background: 'linear-gradient(150deg, #F493B0 0%, #D82298 55%, #9613a4 85%)', border: '1px solid #98373C', color: 'white' },
          {
            background: 'linear-gradient(150deg, #86eae9 0%, #0812DD 65%, #0812DD 75%, white 100%)',
            border: '1px solid #000066',
            color: 'white'
          }
        ]);
      await anim.play();
      this.match.questionNumber++;
      const anim2 = this.animationCtrl.create()
        .addElement(document.getElementById('number-position' + this.match.questionNumber))
        .duration(700)
        .keyframes([
          {
            background: 'linear-gradient(150deg, #fff0a7 0%, #F4AC36 35%, #DB8105 65%, #8b520e 100%)',
            border: '1px solid #CC7205',
            color: '#B13B06'
          },
          { background: 'linear-gradient(150deg, #F493B0 0%, #D82298 55%, #9613a4 85%)', border: '1px solid #98373C', color: 'white' }
        ]);
      await anim2.play();
    }

    console.log('ANSWER', this.question.questionP.alternatives);
  }

  async setPosition() {
    console.log(this.platform.width());
    if (this.platform.width() < 300) {
      for (let index = 0; index < this.numberSmallDevicePositions.length; index++) {
        const item = document.getElementById('number-position' + index);
        item.style.top = this.numberSmallDevicePositions[index].top;
        item.style.left = this.numberSmallDevicePositions[index].left;
      }
    } else {
      for (let index = 0; index < this.numberPositions.length; index++) {
        const item = document.getElementById('number-position' + index);
        item.style.top = this.numberPositions[index].top;
        item.style.left = this.numberPositions[index].left;
      }
    }
    // const avatar = document.getElementById("avatar");
    // avatar.style.top = this.numberPositions[1].top;
    // avatar.style.left = this.numberPositions[1].left;
    const anim = this.animationCtrl.create()
      .addElement(document.getElementById('number-position1'))
      .duration(700)
      .keyframes([
        {
          background: 'linear-gradient(150deg, #fff0a7 0%, #F4AC36 35%, #DB8105 65%, #8b520e 100%)',
          border: '1px solid #CC7205',
          color: '#B13B06'
        },
        { background: 'linear-gradient(150deg, #F493B0 0%, #D82298 55%, #9613a4 85%)', border: '1px solid #98373C', color: 'white' }
      ]);
    await anim.play();
  }

  getValue(index: number) {
    const questionNumber = this.match.questionNumber;
    let value = index;
    if (questionNumber > 4) {
      if (index < 4) { value = questionNumber - (4 - index); }
      else if (index > 4) { value = questionNumber + (index - 4); }
      else { value = questionNumber; }
    }
    return value;
  }

  getMatch() {
    return this.match;
  }

  newQuestion() {
    this.question = new Question(this.questionP);
  }

  newMatch() {
    this.match = new Match(this.util, this.services, this.questionP, this.user);
    this.question = new Question(this.questionP);
  }



  async presentConfigModal() {
    await this.popoverCtrl.create({
      component: ConfigModalPage,
      componentProps: { user: this.user },
      cssClass: 'big-popover',
      backdropDismiss: true
    }).then(popover => popover.present());
  }


}
