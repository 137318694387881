/* eslint-disable max-len */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Platform, PopoverController, ToastController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ServicesService } from './services.service';
import { UserService } from './user.service';
import { primeEnv } from '../../environments/environment';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment.prod';
import { PrimeFinancialRenewPage } from '../modals/prime-financial-renew/prime-financial-renew';
import { AudioService } from './audio.service';

@Injectable({
  providedIn: 'root'
})
export class PrimeService {
  token: string;
  loginAttempts = 3;
  httpHeader: { apiId: string; Authorization: string };
  openedWindow: Window;
  options = 'location=no,footer=no,hidenavigationbuttons=no,fullscreen=yes,clearsessioncache=yes,toolbar=no,zoom=no';

  constructor(public httpClient: HttpClient, private http: HTTP, private inAppBrowser: InAppBrowser, private services: ServicesService,
    private storage: StorageService, private toastCtrl: ToastController, private user: UserService, private platform: Platform,
    private popoverCtrl: PopoverController, private audio: AudioService) {
    this.setHeaders();
  }

  // set Prime headers
  async setHeaders(oauth = null) {
    oauth = oauth || await this.storage.get('prime-oauth');
    if (oauth) { this.httpHeader = { apiId: '10155', Authorization: `Bearer ${oauth}` }; }
  }

  /* Valida se a senha informada via form-data é a senha correta do aluno.
  Se credencial validada, adiciona alunoId do usuário prime ao usuário do enem game.
  Validação necessária uma única vez */
  async validateCredentials(data: { alunoId: number; password: string }) {
    try {
      const response = await this.services.post('prime/validate-credentials', { data });

      this.toastCtrl.create({ message: 'Prime conectado com sucesso', duration: 5000 }).then(toast => toast.present());
      return response;
    } catch (e) {
      this.toastCtrl.create({ message: e.error.message, duration: 5000 }).then(toast => toast.present());
    }
  }

  /* Autenticação OAUTH Enem Game Prime*/
  async getAccessToken(oauthData = {
    client_id: primeEnv.clientId,
    client_secret: primeEnv.clientSecret,
    grant_type: primeEnv.grantType
  }) {
    try {
      const formData = new FormData();
      for (const key in oauthData) { if (Object.prototype.hasOwnProperty.call(oauthData, key)) { formData.append(key, oauthData[key]); } }

      const response = await this.httpClient.post<any>(primeEnv.oauthUrl, formData).toPromise();
      await this.storage.set('prime-oauth', response.access_token);

      return this.setHeaders(response.access_token);
    } catch (e) { throw e; }
  }

  /* Retorna uma URL que pode ser utilizada pelo aluno para já logar aumaticamente no SIG.
Permite que o aluno seja automaticamente redirectionado para outra URL se desejado. */
  async loginGetURL(redirect: string = null) {
    try {
      if (!this.user.prime_email) {
        return;
      }
      this.token = await this.storage.get('token');
      await this.getAccessToken();

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: this.token,
        })
      };
      const body = new URLSearchParams();
      body.set('prime_email', `${this.user.prime_email}`);
      body.set('redirect', `${primeEnv.baseRedirect}`);

      const response = await this.httpClient.post<any>(`${environment.apiUrl}/api/prime/login-get-url`,
        body, httpOptions).toPromise();
      return response;

    } catch (e) {
      if ((e.status === 401 || e.status === -1) && this.loginAttempts > 0) {
        this.httpHeader = null;
        this.loginAttempts--;
        return await this.loginGetURL(redirect);
      } else { throw e; }
    }
  }

  // Login automatico Enem Game Prime - Rota de Aprendizagem

  async handleAccessDateValidation() {
    const loginUrl = await this.loginGetURL();

    const limit_date = loginUrl?.message.financial[0].data_limite_acesso.split('-');


    if (!limit_date) {
      return;
    }

    const newDate = new Date();
    const today = newDate.toISOString().split('T')[0];
    const formattedLimitDate = limit_date[0] + '-' + limit_date[1] + '-' + limit_date[2];
    const todayInMillis = new Date(today).getTime();
    const limitDateInMillis = new Date(formattedLimitDate).getTime();

    const isDateValid = limitDateInMillis >= todayInMillis;

    return isDateValid;

  }
  async automaticPrimeLogin() {
    try {
      const loginUrl = await this.loginGetURL();

      const isDateValid = await this.handleAccessDateValidation();

      if (isDateValid) {
        if (!this.platform.is('cordova')) {
          return this.openedWindow = window.open(loginUrl.message.autoLogin.location, '_blank');
        } else {
          return this.inAppBrowser.create(loginUrl.message.autoLogin.location, '_self', this.options);
        }
      }

      await this.popoverCtrl.create({
        component: PrimeFinancialRenewPage,
        componentProps: { musicID: this.audio.MUSIC_ID.MENU },
        cssClass: 'small-popover',
        backdropDismiss: true

      }).then(popover => popover.present());
    } catch (e) {
      console.error('automaticPrimeLogin error: ', e);
      if (typeof (e) === 'object') {
        for (const key in e) {
          if (Object.prototype.hasOwnProperty.call(e, key)) {
            console.log('------------');
            console.error(String(e[key]));
          }
        }
      }
      this.toastCtrl.create({
        message: 'Problema ao acessar Enem Game Prime. Tente novamente mais tarde.',
        duration: 5000
      }).then(toast => toast.present());
    }
  }

  async regularizePrime() {
    const url = 'https://sis.enemgameprime.com.br/AreaAluno/v4/index.php?area=financeiro-planos';
    try {
      if (this.platform.is('cordova')) {
        this.inAppBrowser.create(url, '_self', this.options);
      }
      else { window.open(url); }
    } catch (e) {
      console.error('regularizePrime error: ');
      if (typeof (e) === 'object') {
        for (const key in e) {
          if (Object.prototype.hasOwnProperty.call(e, key)) {
            console.log('------------');
            console.error(e[key]);
          }
        }
      }
      this.toastCtrl.create({
        message: 'Problema ao acessar Enem Game Prime. Tente novamente mais tarde.',
        duration: 5000
      }).then(toast => toast.present());
    }
  }

}
