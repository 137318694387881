import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { AdMob } from 'community-admob-plus-cordova';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GameMenuModule } from './components/game-menu/game-menu.module';
import { ObjectivesModule } from './components/objectives/objectives.module';
import { ParticleFxModule } from './components/particle-fx/particle-fx.module';
import { AchievementCardModalPageModule } from './modals/achievement-card-modal/achievement-card-modal.module';
import { ActiveObjectivesPopoverPageModule } from './modals/active-objectives-popover/active-objectives-popover.module';
import { AvatarsCoversPageModule } from './modals/avatars-covers/avatars-covers.module';
import { BugReportPopoverPageModule } from './modals/bug-report-popover/bug-report-popover.module';
import { FullModalPageModule } from './modals/full-modal/full-modal.module';
import { AchievementsService } from './services/achievements.service';
import { ClearServicesService } from './services/clear-services.service';
import { AudioService } from './services/audio.service';
import { EconomyService } from './services/economy.service';
import { LeaderboardService } from './services/leaderboard.service';
import { LogCatcherService } from './services/log-catcher.service';
import { QuestionService } from './services/question.service';
import { ServicesService } from './services/services.service';
import { SocialService } from './services/social.service';
import { StatisticsService } from './services/statistics.service';
import { UserService } from './services/user.service';
import { UtilService } from './services/util.service';
import { NoUserMatchGameplay } from './single-player/no-user-match/no-user-match-gameplay';
import { SinglePlayerGameplay } from './single-player/single-player-game/single-player-gameplay';
import { RequestXtraInfoPageModule } from './modals/request-xtra-info/request-xtra-info.module';
import { PrimeModalPageModule } from './modals/prime-modal/prime-modal.module';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { RedeemPrizePageModule } from './modals/redeem-prize/redeem-prize.module';
import { ProfilePopoverPageModule } from './modals/profile-popover/profile-popover.module';
import { InfoPopoverPageModule } from './modals/info-popover/info-popover.module';
import { ConfigModalPageModule } from './modals/config-modal/config-modal.module';
import { AbandonModalPageModule } from './modals/abandon-modal/abandon-modal.module';
import { AvatarCoverPopoverPageModule } from './avatar-cover-popover/avatar-cover-popover.module';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { PrimeFinancialRenewPageModule } from './modals/prime-financial-renew/prime-financial-renew.module';
import { RankingPopoverPageModule } from './modals/ranking-popover/ranking-popover.module';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ActiveObjectivesPopoverPageModule,
    AchievementCardModalPageModule,
    AppRoutingModule,
    AvatarsCoversPageModule,
    BrowserModule,
    BugReportPopoverPageModule,
    RedeemPrizePageModule,
    ProfilePopoverPageModule,
    InfoPopoverPageModule,
    RankingPopoverPageModule,
    ConfigModalPageModule,
    AbandonModalPageModule,
    AvatarCoverPopoverPageModule,
    /* CustomMenuModule, */
    FormsModule,
    FullModalPageModule,
    GameMenuModule,
    HttpClientModule,
    PrimeModalPageModule,
    PrimeFinancialRenewPageModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ObjectivesModule,
    ParticleFxModule,
    ReactiveFormsModule,
    RequestXtraInfoPageModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AchievementsService,
    AdMob,
    AngularFireDatabase,
    AngularFireAuth,
    AppRate,
    HttpClientModule,
    HTTP,
  // TODO reimplementar
  // StatusBar,
  // SplashScreen,
  { provide: ErrorHandler },
    ServicesService,
    Facebook,
    FirebaseX,
    UserService,
    UtilService,
    QuestionService,
    SinglePlayerGameplay,
    NoUserMatchGameplay,
    EconomyService,
    Insomnia,
    // ObjectivesService,
    StatisticsService,
    LogCatcherService,
    SocialService,
    LeaderboardService,
    ClearServicesService,
    NativeAudio,
    AudioService,
    AppVersion,
    Network,
    Device,
    InAppBrowser,
    ScreenOrientation,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
