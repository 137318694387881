import { Component } from '@angular/core';
import { AlertController, LoadingController, ToastController } from "@ionic/angular";
import { AudioService } from 'src/app/services/audio.service';
// import { ObjectivesService } from 'src/app/services/objectives.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'objectives',
    templateUrl: 'objectives.component.html',
    styleUrls: ['objectives.component.scss']
})
export class ObjectivesComponent {

    public fx: boolean = false;
    public fxGold: boolean = false;
    public progressBarDuration = 1;
    public progressBarColor = "#679E4F";
    public progressBarBG = "2F4184";
    public animating;
    public oldLevel;

    constructor(/* public objectives: ObjectivesService, */
        public user: UserService,
        public alertCtrl: AlertController,
        public loadingCtrl: LoadingController,
        public toastCtrl: ToastController,
        private audio: AudioService) {
        let initInterval = setInterval(() => {
            if (this.user.getId()) {
                clearInterval(initInterval);
                this.user.calculateLevels();
                this.oldLevel = this.user.getUserInfo().level;
                window.setTimeout(() => {
                    this.changeLevelValues(0);
                }, 2000);
            }
        }, 1000);
    }

    private async redeemPrize(objective) {
        if (objective.currentValue >= objective.requirement && !objective.completed) {
            let loading = await this.loadingCtrl.create({ message: "Resgatando prêmio..." });
            await loading.present();
            // this.objectives.postCompletedDailyObjectiveToServer(objective).then(async res => {
            //     console.log("res", res);
            //     if (objective.prizeType == 'XP') {
            //         this.animating = !this.animating;
            //         setTimeout(() => {
            //             this.changeLevelValues(objective.prizeValue);
            //             window.setTimeout(() => {
            //                 this.animating = !this.animating;
            //                 if (this.oldLevel != this.user.getUserInfo().level) this.showfx();

            //             }, this.progressBarDuration);
            //         }, 1000);
            //     } else {
            //         this.user.updateMoney('gain', objective.prizeValue);
            //         this.showfxGold();
            //     }
            //     await loading.dismiss();
            // }).catch(async e => {
            //     await loading.dismiss();
            //     if (e.status == 401) this.toastCtrl.create({ message: "Já resgatado", duration: 3000 }).then(toast => toast.present);
            // });

            // DEPRECATED
            /*if (objective.prizeType == 'XP') {
                this.animating = !this.animating;
                setTimeout(() => {
                    this.changeLevelValues(objective.prizeValue);
                    window.setTimeout(() => {
                        this.animating = !this.animating;
                        if (this.oldLevel != this.user.getUserInfo().level) this.showfx();

                    }, this.progressBarDuration);
                }, 1000);
            } else {
                this.user.updateMoney('gain', objective.prizeValue);
                this.showfxGold();
            }
            setTimeout(() => {
                this.objectives.postCompletedDailyObjectiveToServer(objective);
            }, 2000);*/
        }
    }

    showfx() {
        this.fx = !this.fx;
        if (this.fx) this.audio.play(this.audio.SFX_ID.LEVEL_UP);
        setTimeout(() => {
            this.fx = !this.fx;
        }, 1000);
    }

    showfxGold() {
        this.fxGold = !this.fxGold;
        /**Sound Effect*/
        if (this.fxGold) this.audio.play(this.audio.SFX_ID.PROGRESS_GOLD);
        setTimeout(() => {
            this.fxGold = !this.fxGold;
        }, 1000);
    }

    changeLevelValues(score) {
        this.audio.play(this.audio.SFX_ID.PROGRESS_XP);
        this.oldLevel = this.user.calculateLevel();
        this.user.setScore(score);
        this.progressBarDuration = 1000;
        if (this.oldLevel != this.user.calculateLevel()) {
            this.user.levels.progressInLevel = this.user.levels.differenceBetweenLevels;
            window.setTimeout(() => {
                this.progressBarDuration = 1;
                this.progressBarBG = this.progressBarColor;
                this.user.levels.progressInLevel = 0;
                this.progressBarColor = "#216C32";
                window.setTimeout(() => {
                    this.progressBarDuration = 1000;
                    this.user.calculateLevels();
                }, 10);
            }, 1100);
        } else {
            this.user.calculateLevels();
        }
    }

    private async showObjDescription(objective) {
        await this.alertCtrl.create({
            header: objective.name,
            message: objective.description,
            buttons: ['OK']
        }).then(alert => alert.present());
    }

    public handleObjectiveClick(objective) {
        if (objective.currentValue >= objective.requirement && !objective.completed) {
            return this.redeemPrize(objective);
        } else {
            return this.showObjDescription(objective)
        }
    }

    round(num) {
        return Math.round(num * 100) / 100;
    }

}
