import { Component } from '@angular/core';
// import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { ModalController, NavController, NavParams, Platform } from '@ionic/angular';
import { ProfileComparePage } from 'src/app/profile-compare/profile-compare.page';
import { ServicesService } from 'src/app/services/services.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'page-full-modal',
    templateUrl: 'full-modal.html',
})
export class FullModalPage {
    public name: string;
    public avatar: string;
    public school: string;
    public grade: string;
    public level: number;
    public intendedCourses: string;
    public toolbar = true;
    public challenge = false;
    public viewProfile = true;
    public otherUser;
    public cover;
    private unregisterBackButton;

    constructor(public modalCtrl: ModalController,
        public navCtrl: NavController,
        public navParams: NavParams,
        public platform: Platform,
        private services: ServicesService,
        private user: UserService,
        private util: UtilService,
        // public fb: Facebook
    ) {
        const toolbar = this.navParams.get('toolbar');
        const challenge = this.navParams.get('challenge');
        const viewProfile = this.navParams.get('viewProfile');
        if (toolbar !== undefined) {this.toolbar = toolbar;}
        if (challenge !== undefined) {this.challenge = challenge;}
        if (viewProfile !== undefined) {this.viewProfile = viewProfile;}
        const rankingUser = this.navParams.get('user');
        if (rankingUser) {
            this.bindPreviouslyLoadedData(rankingUser, this.navParams.get('avatar'));
            this.services.get('auth/whois', {
                data: { userId: rankingUser.id ? rankingUser.id : rankingUser.userId },
                success: res => {
                    this.otherUser = res.data;
                    this.bindData(res.data);
                },
                error: e => console.error(e)
            });
        } else {
            this.bindData(this.user);
        }
        // TODO Analisar alternativa pós migração ionic 3 para 4
        // this.unregisterBackButton = this.platform.registerBackButtonAction(() => this.viewCtrl.dismiss(), 10);
    }

    public ionViewWillLeave() {
        // this.unregisterBackButton();
    }

    private static filterCourseCallback(course) {
        return course && course != -1 && ['null', 'undefined'].includes(String(course).toLowerCase());
    }

    bindPreviouslyLoadedData(user, avatar) {
        this.name = user.firstName;
        this.avatar = avatar ? avatar : user.avatar;
        this.level = user.level;
    }

    private bindData(data) {
        if (!this.name || this.avatar) {
            this.name = data.profile && data.profile.firstName || data.username;
            this.avatar = this.util.getAvatar(data.profile && data.profile.avatar, data.facebookId);
        }
        if (!this.level) {this.level = data.level;}
        this.school = data.school && data.school.name;
        this.grade = data.profile && data.profile.grade;
        this.intendedCourses = data.profile && data.profile.intendedCourses && data.profile.intendedCourses
            .filter(FullModalPage.filterCourseCallback)
            .join(', ') || '';
        this.getCoverImage(data);
    }

    public openProfile(otherUser) {
        this.modalCtrl.dismiss();
        console.log('this cover before push: ', this.cover);
        this.navCtrl.navigateForward('/profile', {
            queryParams: {
                type: 'otherUserProfile',
                user: otherUser,
                coverImage: this.cover
            }
        });
    }

    public compareProfile(otherUser) {
        this.modalCtrl.dismiss();
        this.navCtrl.navigateForward('/profile-compare', { queryParams: { type: 'otherUserProfile', user: otherUser } });
    }

    getCoverImage(user) {
        this.cover = this.util.getCover(user.profile.coverImage, user.facebookId);
        /* if (user.facebookId) {
             this.fb.api('/' + this.user.FB_API_VERSION + '/' + user.facebookId + "/?fields=cover", ["email", "public_profile", "user_friends"]).then(res => {
                 if (res.cover && res.cover.source) this.cover = res.cover.source;
                 else this.cover = 'assets/imgs/covers/c4.jpg';
             }).catch(e => {
                 console.warn("Error on getting other user's facebook cover image", e);
                 this.cover = 'assets/imgs/covers/c4.jpg';
             });
         } else this.cover = 'assets/imgs/covers/c4.jpg';*/
    }
}
