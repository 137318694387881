import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AvatarsCoversPage } from '../modals/avatars-covers/avatars-covers';
import { AudioService } from '../services/audio.service';

@Component({
  selector: 'app-avatar-cover-popover',
  templateUrl: 'avatar-cover-popover.page.html',
  styleUrls: ['./avatar-cover-popover.scss']
})
export class AvatarCoverPopoverPage implements OnInit {

  constructor(public navCtrl: NavController, public popoverCtrl: PopoverController,
    public audio: AudioService) {
  }

  ngOnInit() {
    console.log('ngOnInit AvatarCoverPopoverPage');
  }

  async changeAvatarPopover() {
    let popover = await this.popoverCtrl.create({
      component: AvatarsCoversPage, componentProps: { type: 'avatar' },
      cssClass: "pop-default change-avatar", showBackdrop: true, backdropDismiss: true
    });
    await popover.present();
    this.audio.play(this.audio.SFX_ID.POPOVER_PRESENT);
  }

  async changeCoverPopover() {
    let popover = await this.popoverCtrl.create({
      component: AvatarsCoversPage, componentProps: { type: 'cover' },
      cssClass: "pop-default change-avatar",
      showBackdrop: true,
      backdropDismiss: true
    });
    await popover.present();
    this.audio.play(this.audio.SFX_ID.POPOVER_PRESENT);
  }

}
