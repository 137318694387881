import { Component } from '@angular/core';
import { NavParams, Platform, PopoverController } from '@ionic/angular';
import { AudioService } from 'src/app/services/audio.service';
import { UserService } from 'src/app/services/user.service';
import { ConfigModalPage } from '../config-modal/config-modal';

@Component({
  selector: 'info-popover',
  templateUrl: 'info-popover.html',
  styleUrls: ['./info-popover.scss']
})
export class InfoPopoverPage {

  public notifStatus = true;
  constructor(
    private popoverCtrl: PopoverController,
    public platform: Platform,
    private audio: AudioService,
    private user: UserService,
    public navParams: NavParams,) {}

  async closeModal() {
    const popover = await this.popoverCtrl.getTop();

    if (popover) {
      await popover.dismiss(null);
    }
  }

  async backToConfig() {
    this.audio.play(this.audio.SFX_ID.POPOVER_PRESENT);
    await this.closeModal();
    let popover = await this.popoverCtrl.create({
      component: ConfigModalPage,
      componentProps: { user: this.user, gameType: this.navParams.get('gameType') },
      cssClass: 'big-popover',
      backdropDismiss: true
    });
    await popover.present();
  }
}
