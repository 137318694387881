import { QuestionService } from "../services/question.service";

export class Question {

    //Const
    private TOOLS_PER_QUESTION = 1;

    private startedAt;

    private alternativeSelected = false;
    private questionAnswered = false;
    public answerQuantity = 1;
    public eliminatedLetters = [];
    public eliminated = 0;
    public questionToolQuantity = this.TOOLS_PER_QUESTION;
    public answerTwo = false;
    public answerTwoSelectedAlternatives = [];
    private skippedQuestion = false;

    //QUESTION TIMER
    private questionTime = 0;
    private minutes = 0;
    private seconds = 0;
    private timer;

    // public matchTime: any;

    constructor(private questionP: QuestionService) {
        this.startedAt = new Date();
    }

    // METHODS
    //Question Timer
    questionTimer() {
        this.timer = setInterval(x => {
            this.questionTime++;
            this.questionP.matchTime++;
            if (this.seconds < 59) {
                this.seconds++;
            } else {
                this.seconds = 0;
                this.minutes++;
            }
        }, 1000);
    }

    //GETTERS AND SETTERS

    setSkippedQuestion(isSkipped) {
        this.skippedQuestion = isSkipped;
    }

    setAlternativeSelected(isSelected) {
        this.alternativeSelected = isSelected;
    }

    setQuestionAnswered(isAnswered) {
        this.questionAnswered = isAnswered;
    }

}
