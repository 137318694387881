import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ServicesService } from './services.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  //QUESTION INFO
  private questionUrl;
  private questions = [];
  public question;
  private questionIndex = 0;
  public questionContent;
  public alternatives;
  private disciplines;
  public questionReady;
  public questionPrepared;

  //QUESTION TIMER
  public matchTime: any;

  //CONSTRUCTOR
  constructor(private user: UserService, private domSanitizer: DomSanitizer, private services: ServicesService) {
    this.questionUrl = 'questions/random?acronym=';
  }

  //METHODS

  //getters and setters

  getQuestion() {
    return this.question;
  }

  //Questions Methods
  public getQuestions(disciplines, match, forceClear: boolean = true) {
    this.disciplines = disciplines;
    return this.services.get(this.questionUrl + disciplines, { data: { quantity: 10 } }).then(res => {
      if (forceClear) {
        this.questions = [];
        this.questionIndex = 0;
      }
      let callNewQuestion = !this.questions || !this.questions.length;
      this.questions.push(...res.data);
      if (callNewQuestion) {
        this.questionIndex = 0;
        return this.newQuestion(match);
      }
    });
    /*, {
        data: {quantity: 10},
        success: res => {
            console.log("questions request success", res.data);
            let callNewQuestion = !this.questions || !this.questions.length;
            this.questions.push(...res.data);
            if (callNewQuestion) this.newQuestion(match, null);
        },
        error: function (err) {
            console.log("Error getting questions: ", err);
        }
    });*/
  }

  public newQuestion(match, questionNumber: number | null = null) {
    if (!this.questions || !this.questions.length) {
      this.getQuestions(this.disciplines, match);
    }
    else {
      this.question = this.questions[this.questionIndex];
      if (this.user && this.user.getId()) match.addQuestion(this.question);
      let correctAlternative = this.question.correctIndex;
      // console.log("CORRECT", String.fromCharCode(65+correctAlternative));
      this.alternatives = this.question.alternatives.map((alt, i) => ({
        text: this.domSanitizer.bypassSecurityTrustHtml(alt.text),
        isCorrect: correctAlternative == i,
        isSelected: false,
        letter: String.fromCharCode(65 + i),
        id: alt.id
      }));
      this.questionContent = {
        questionText: this.domSanitizer.bypassSecurityTrustHtml(this.question.text),
        alternatives: this.alternatives
      };
      this.questionIndex++;
      this.questionPrepared = true;
      if (this.questions.length - this.questionIndex < 4) {
        this.getQuestions(this.disciplines, match, false);
      }
    }
  }
}
