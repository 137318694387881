import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingController, NavController, NavParams, ToastController, PopoverController } from '@ionic/angular';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'page-request-xtra-info',
  templateUrl: 'request-xtra-info.html',
  styleUrls: ['request-xtra-info.scss'],
})
export class RequestXtraInfoPage implements OnInit {
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;

  public stateList: any[];
  public form: UntypedFormGroup;
  public cityList: any[];
  public staticCityList: any[];
  public schoolList: any[];
  public staticSchoolList: any[];
  public currentState;
  public currentSchool;
  public grades: any[];
  public noSchool = null;
  public isLoaded = false;
  public step = 0;

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    private popoverCtrl: PopoverController,
    public user: UserService,
    private services: ServicesService,
    private formBuilder: UntypedFormBuilder,
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController) {
    this.form = this.formBuilder.group({
      state: ['', Validators.pattern(/^[a-zA-Z]{2}$/)],
      city: [''],
      schoolId: [0, Validators.pattern(/\d+/)],
      grade: ['', Validators.pattern(/^(?:1|2|3|outro)$/i)],
    });
    this.grades = ['1', '2', '3', 'Outro'];
  }

  async ngOnInit() {
    const loading = await this.loadingCtrl.create({ message: 'Carregando...' });
    console.log('ngOnInit RequestXtraInfoPage');
    if (!this.stateList) {
      await loading.present();
      this.services.get('/locations/states', {
        success: async res => {
          await loading.dismiss();
          this.stateList = res.sort();
        },
        error: async e => {
          await loading.dismiss();
          console.error('error loading states', e);
          this.toastCtrl.create({
            message: 'Ocorreu um erro, tente novamente mais tarde',
            duration: 5000
          }).then(toast => toast.present());
        }
      });
    }
    this.isLoaded = true;
  }

  public stateChanged(event: any) {
    this.currentState = event.detail.value;
    this.getCities(event.detail.value);
  }

  cityChanged(event: any) {
    this.getSchools(event.detail.value);
  }

  async schoolChanged(event: any) {
    this.cityList = this.staticCityList;
    this.schoolList = this.staticSchoolList;
    if (this.schoolList && event.detail.value > 0) {
      this.currentSchool = this.schoolList.find(school => school.id == event.detail.value);
      //this.goToNextSlide();
      this.step = 3;
    } else {
      //this.slideTo(await this.slides.getActiveIndex() + 2);
      this.step = 4;
    }
  }

  gradeChanged(event: any) {
    console.log('grade changed');
    //this.goToNextSlide();
    this.step = 4;
  }

  public async getSchools(cityName) {
    const loading = await this.loadingCtrl.create({ message: 'Carregando...' });
    if (!cityName) { return this.schoolList = []; }
    const city = this.cityList.find(city => city.name == cityName);
    console.log('city', this.cityList);
    await loading.present();
    await this.services.get(`/locations/states/${this.currentState}/cities/${city.slug}/schools`, {
      success: async res => {
        await loading.dismiss();
        this.goToNextSlide();
        this.swiperRef.nativeElement.swiper.slideNext();
        this.schoolList = this.staticSchoolList = res.data;
        this.step = 2;
      },
      error: async e => {
        await loading.dismiss();
        this.toastCtrl.create({
          message: 'Ocorreu um erro, tente novamente mais tarde',
          duration: 5000
        }).then(toast => toast.present());
        console.error('Error loading schools from city: ', e);
      }
    });
  }

  getFilteredSchools(ev: any) {
    this.schoolList = this.staticSchoolList;
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.schoolList = this.schoolList.filter(item => (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1));
    }
  }


  public async getCities(state) {
    if (!state || state instanceof Array) {
      this.currentState = null;
      return this.cityList = [];
    } else {
      const loading = await this.loadingCtrl.create({ message: 'Carregando...' });
      await loading.present();
      await this.services.get(`/locations/states/${state}/cities`, {
        success: async res => {
          //this.goToNextSlide();
          await loading.dismiss();
          this.cityList = res.sort((a, b) => {
            if (a.slug < b.slug) { return -1; }
            return 1;
          }).map(city => city);
          this.staticCityList = this.cityList;
          this.step = 1;
        },
        error: async e => {
          await loading.dismiss();
          console.error('error loading cities from state', e);
          this.toastCtrl.create({
            message: 'Ocorreu um erro, tente novamente mais tarde',
            duration: 5000
          }).then(toast => toast.present());
        }
      });
    }
  }

  getFilteredCities(ev: any) {
    this.cityList = this.staticCityList;
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.cityList = this.cityList.filter(item => (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1));
    }
  }

  async saveProfile() {
    if (this.form.valid) {
      const data: any = {};
      ['state', 'city', 'schoolId', 'grade']
        .forEach(key => data[key] = this.form.controls[key].value);
      if (!this.form.controls.grade.value) { data.grade = 'Outro'; }
      console.log('schoolId', this.form.controls.schoolId.value);
      data.firstName = this.user.getUserInfo().profile.firstName;
      data.lastName = this.user.getUserInfo().profile.lastName;
      const loading = await this.loadingCtrl.create({ message: 'Salvando...' });
      console.log('data', data);
      await loading.present();
      return this.services.put(`users/full-update/${this.user.getId()}`, { data }).then(async res => {
        this.user.profile.state = this.form.controls.state.value;
        this.user.profile.city = this.form.controls.city.value;
        if (this.schoolList && this.form.controls.schoolId.value > 0) { this.user.school = this.currentSchool; }
        if (this.form.controls.grade.value && this.form.controls.grade.value != 'Outro') { this.user.profile.grade = this.form.controls.grade.value; }
        await loading.dismiss();
        this.toastCtrl.create({ message: 'Perfil atualizado com sucesso', duration: 5000 }).then(toast => toast.present());
        this.popoverCtrl.dismiss('success');
      }).catch(async e => {
        await loading.dismiss();
        console.error('error on updating user data', e);
        this.toastCtrl.create({
          message: 'Ocorreu um erro ao tentar atualizar o perfil, tente novamente mais tarde.',
          duration: 5000
        }).then(toast => toast.present());
      });
    }
  }

  resetValues(origin) {
    this.form.reset();
    // this.cityList = this.schoolList = [];
    this.currentState = null;
    this.currentSchool = null;
    origin == 'correct' ? this.slideTo(0) : this.popoverCtrl.dismiss();
  }

  goToNextSlide() {
    this.swiperRef.nativeElement.swiper.slideNext();
  }

  slideTo(slide) {
    this.swiperRef.nativeElement.swiper.slideTo(slide);
  }

  async closeModal() {
    this.popoverCtrl.dismiss();
  }

  castToFormControl(absCtrl: AbstractControl): UntypedFormControl {
    const ctrl = absCtrl as UntypedFormControl;
    // if(!ctrl) throw;
    return ctrl;
  }
}
