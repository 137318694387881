export const environment = {
  production: true,
  apiUrl: 'https://services.enemgame.com.br/v255',
  firebase: {
    apiKey: 'AIzaSyBNP1IDAnEpV_5ujEVmToNbV6-dFds8TTM',
    authDomain: 'enem-game-37825276.firebaseapp.com',
    databaseURL: 'https://enem-game-37825276.firebaseio.com',
    projectId: 'enem-game-37825276',
    storageBucket: 'enem-game-37825276.appspot.com',
    messagingSenderId: '571346868842',
    appId: '1:571346868842:web:02ba89fc9cd9243d3f0813',
    measurementId: 'G-W693JEH29W'
  }
};

export const primeEnv = {
  oauthUrl: 'https://sis.enemgameprime.com.br/oauth/token',
  apiUrl: 'https://sis.enemgameprime.com.br/api/v2',
  clientId: 1001,
  clientSecret: 'AYbPb5Dn7bFrsfDMHueRrT7PP2JTYMSbnTBqz5nw',
  grantType: 'client_credentials',
  apiId: '10155',
  studentProfile: 2,
  baseRedirect: 'https://sis.enemgameprime.com.br/sistema/rota/rotas_2/13189/xapi/'
};
