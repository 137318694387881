import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule, NavParams } from '@ionic/angular';
import { RoundprogressModule } from 'angular-svg-round-progressbar';
import { ConfigModalPage } from './config-modal';

@NgModule({
  declarations: [
    ConfigModalPage,
  ],
  imports: [
    RouterModule,
    CommonModule,
    IonicModule.forRoot(),
    RoundprogressModule
  ],
  providers: [NavParams]
})
export class ConfigModalPageModule {}
