import { Component } from '@angular/core';
import { Router } from '@angular/router';
// import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { LoadingController, NavController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { AudioService } from 'src/app/services/audio.service';
import { ServicesService } from 'src/app/services/services.service';
import { UserService } from 'src/app/services/user.service';
import { AbandonModalPage } from '../abandon-modal/abandon-modal';
import { BugReportPopoverPage } from '../bug-report-popover/bug-report-popover';
import { DeleteAccountPage } from '../delete-account-popover/delete-account';
import { InfoPopoverPage } from '../info-popover/info-popover';
import { AdsService } from 'src/app/services/ads.service';

@Component({
  selector: 'config-modal',
  templateUrl: 'config-modal.html',
  styleUrls: ['./config-modal.scss']
})
export class ConfigModalPage {

  public gameplayMode = false;
  public notifStatus = true;
  // private FACEBOOK_PERMISSIONS: ["email", "public_profile", "user_friends"];
  isLoggedInFb: boolean;

  constructor(
    private ads: AdsService,
    private popoverCtrl: PopoverController,
    public platform: Platform,
    private router: Router,
    public audio: AudioService,
    public user: UserService,
    // private fb: Facebook,
    private navCtrl: NavController,
    public navParams: NavParams,
    private services: ServicesService,
    private loading: LoadingController) {
    if (this.navParams.get('gameType') === 'gameplay') {
      this.gameplayMode = true;
    }
  }

  async closeModal() {
    const popover = await this.popoverCtrl.getTop();

    if (popover) {
      await popover.dismiss(null);
    }
  }

  async goToAbandon() {
    await this.closeModal();

    await this.popoverCtrl.create({
      component: AbandonModalPage,
      cssClass: 'red-popover',
      showBackdrop: true,
      backdropDismiss: true
    }).then(popover => popover.present());
  }

  toggleFX() {
    this.audio.changeMuteFXState();
  }

  toggleMusic() {
    console.log('this.audio', this.audio.muteMusic);
    this.audio.changeMuteMusicState();
  }

  toggleNotification() {
    this.notifStatus = !this.notifStatus;
  }

  async gotToInfo() {
    this.audio.play(this.audio.SFX_ID.POPOVER_PRESENT);
    await this.closeModal();
    const popover = await this.popoverCtrl.create({
      component: InfoPopoverPage,
      cssClass: 'big-popover',
      componentProps: { gameType: this.navParams.get('gameType') },
      showBackdrop: true,
      backdropDismiss: true
    });
    await popover.present();
  }

  async removeAccount() {
    this.closeModal();
    await this.popoverCtrl.create({
      component: DeleteAccountPage,
      cssClass: 'red-popover',
      showBackdrop: true,
      backdropDismiss: true
    }).then(popover => popover.present());
  }

  async disconnect() {
    const success = res => {
      // this.fb.getLoginStatus().then(res => {
      //   if (res.status === "connected") this.fb.logout().then(res => console.info("Successfully disconnected from Facebook: ", res))
      // .catch(err => console.error("Error disconnecting from Facebook: ", err));
      // });
      this.navCtrl.navigateRoot('welcome');

    };
    this.closeModal();
    this.services.logout({ success });
    if (this.ads.banner) {
      await this.ads.banner.hide();
    }
  }

  async goToProfile() {
    await this.closeModal();
    this.router.navigate(['profile-edit']);
  }

  async callSupport() {
    this.audio.play(this.audio.SFX_ID.POPOVER_PRESENT);
    await this.closeModal();
    const popover = await this.popoverCtrl.create({
      component: BugReportPopoverPage,
      componentProps: { gameType: this.navParams.get('gameType') },
      cssClass: 'big-popover bug-report',
      showBackdrop: true,
      backdropDismiss: true
    });
    await popover.present();
  }

  // async fbLogin() {
  //   let loading = this.loading;
  //   await loading.create()
  //   this.fb.login(this.FACEBOOK_PERMISSIONS).then(res => {
  //     if (res.status === "connect") {
  //       this.isLoggedInFb = true;
  //       // this.getFbUserDetail(res.authResponse.userID);
  //     } else {
  //       this.isLoggedInFb = false;

  //     }
  //   }).catch(e => console.error('Error loggin into Facebook', e))
  //     .finally(async () => await loading.dismiss());
  // }


  // getFbUserDetail(userId) {
  //   this.fb.api("/" + userId + "/?fields=id,email,name,picture,gender", ["public_profile"])
  //     .then(res => {
  //       console.log(res);
  //       this.user = res;
  //     })
  //     .catch(e => {
  //       console.error(e);
  //     });
  // }
}
