import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingController, NavController, Platform, PopoverController } from '@ionic/angular';
import { ServicesService } from 'src/app/services/services.service';
import { SHA512 } from 'crypto-js';
import { UtilService } from 'src/app/services/util.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: 'delete-account.html',
  styleUrls: ['./delete-account.scss']
})
export class DeleteAccountPage {

  public isForm = false;
  public showPassword = false;
  public credentialsForm: UntypedFormGroup;

  constructor(
    private popoverCtrl: PopoverController, public platform: Platform,
    private formBuilder: UntypedFormBuilder, private loadingCtrl: LoadingController,
    private services: ServicesService, private util: UtilService, public user: UserService,
    private navCtrl: NavController) {
    this.credentialsForm = this.formBuilder.group({
      username: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  get username() {
    return this.credentialsForm.get('username');
  }

  get password() {
    return this.credentialsForm.get('password');
  }

  async closeModal() {
    const popover = await this.popoverCtrl.getTop();
    if (popover) {
      await popover.dismiss(null);
    }
  }

  goToForm() {
    if (this.user.facebookId || this.user.googleId) {
      this.delete();
    } else {
      this.isForm = true;
    }
  }

  async delete() {
    const loading = this.loadingCtrl.create().then(e => e);
    (await loading).present();
    const success = async (res) => {
      (await loading).dismiss();
      this.services.delete('users').then(_ => {
        this.services.logout({ success: () => this.navCtrl.navigateRoot('welcome') });
      });
    };
    const error = async (err) => {
      console.error('Erro ao tentar logar: ', err);
      (await loading).dismiss();
      switch (err.status) {
        case 403:
          await this.util.showFeedbackAlert('Erro no Login', 'Usuário ou senha inválidos.');
          break;
        case 400:
          await this.util.showFeedbackAlert('Erro no login', 'Você precisa digitar o seu nome de usuário e sua senha.');
          break;
        default:
          await this.util.showFeedbackAlert('Erro no login',
            'Ocorreu um erro ao tentar logar. ' +
            'Tente novamente mais tarde ou entre em contato com a equipe de suporte através do e-mail: ' +
            'suporte@enemgame.com.br');
      }
    };
    if (this.isForm) {
      const data = {
        username: this.credentialsForm.value.username,
        password: SHA512(this.credentialsForm.value.password).toString()
      };
      if (data.username !== this.user.username) {
        (await loading).dismiss();
        return this.credentialsForm.get('username').setErrors({ compative: true });
      }
      this.services.login({ data, success, error });
      await this.closeModal();
    } else {
      success(null);
      await this.closeModal();
    }
  };

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
