import { Component } from '@angular/core';

/**
 * Generated class for the ParticleFxComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'particle-fx',
  templateUrl: 'particle-fx.component.html',
  styleUrls: ['particle-fx.component.scss']
})
export class ParticleFxComponent {

  public particles : number[] = Array.from(Array(30), (v, i)=>i);

  constructor() {

  }

}
