import { NgModule } from '@angular/core';
import { ObjectivesComponent } from './objectives.component';
import { RoundProgressModule } from "angular-svg-round-progressbar";
import { ParticleFxModule } from "../particle-fx/particle-fx.module";
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [ObjectivesComponent],
    imports: [
        RouterModule,
        CommonModule,
        IonicModule.forRoot(),
        RoundProgressModule,
        ParticleFxModule,
    ],
    exports: [ObjectivesComponent]
})
export class ObjectivesModule {
}
