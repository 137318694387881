import { Component } from '@angular/core';
import { AlertController, NavController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'page-profile-popover',
  templateUrl: 'profile-popover.html',
})
export class ProfilePopoverPage {
  private unregisterBackButton;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public alertCtrl: AlertController,
    public platform: Platform,
    public popoverCtrl: PopoverController,
    private util: UtilService,
    public user: UserService) {
    // TODO Analisar alternativa pós migração ionic 3 para 4
    // this.unregisterBackButton = this.platform.registerBackButtonAction(() => this.viewCtrl.dismiss(), 10);
  }

  public ionViewWillLeave() {
    // this.unregisterBackButton();
  }

  goToProfileEditPage() {
    // Popovers should not push pages. Instead, its parent is the one that should push it.
    // That's why the last active NavController instance is the one that pushes it in the code below
    // while the "normal" (not in a popover component) uses the "this.navCtrl.push" method
    // TODO checar como vai ficar no ionic novo
    // let activeNavs = this.app.getActiveNavs();
    // let nav = activeNavs[activeNavs.length - 1];
    this.navCtrl.navigateForward('/profile-edit');
    this.popoverCtrl.dismiss();
  }

  async changePassword() {
    const changePasswordAlert = await this.alertCtrl.create({
      header: 'Alterar senha',
      message: 'Tem certeza que deseja alterar sua senha?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: data => {
            console.log('Change Password Cancel Button clicked: ', data);
          }
        },
        {
          text: 'Sim',
          handler: async data => {
            await changePasswordAlert.dismiss();
            const email = this.user.getUserInfo().email;
            this.util.resetPassword(email);
            const status = await this.util.resetPassword(data.email)
              .then((v) => {
                this.util.showFeedbackAlert('Sucesso!', 'Foi enviada uma mensagem para o seu e-mail ('
                  + data.email
                  + '). \nVocê poderá alterar sua senha através do link na mensagem.');
              })
              .catch(err => {
                switch (err.status) {
                  case 404: {
                    this.util.showFeedbackAlert('E-mail não encontrado',
                      'Este e-mail não está cadastrado. \nPor favor verifique seu e-mail.');
                    break;
                  }
                  case 400: {
                    this.util.showFeedbackAlert('E-mail não encontrado', 'Você deve inserir seu email para resetar sua senha.');
                    break;
                  }
                  default: {
                    this.util.showFeedbackAlert('Erro na alteração da senha',
                      'Ocorreu um erro ao tentar alterar sua senha.'
                      + ' Tente novamente mais tarde ou entre em contato com a equipe de suporte através do e-mail: suporte@enemgame.com.br');
                    break;
                  }
                }
              });
            return false;
          }
        }]
    });
    await changePasswordAlert.present();
  }
}
