// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://services.enemgame.com.br/v255',
  // apiUrl: 'http://192.168.0.32:3840',
  firebase: {
    apiKey: 'AIzaSyBNP1IDAnEpV_5ujEVmToNbV6-dFds8TTM',
    authDomain: 'enem-game-37825276.firebaseapp.com',
    databaseURL: 'https://enem-game-37825276.firebaseio.com',
    projectId: 'enem-game-37825276',
    storageBucket: 'enem-game-37825276.appspot.com',
    messagingSenderId: '571346868842',
    appId: '1:571346868842:web:02ba89fc9cd9243d3f0813',
    measurementId: 'G-W693JEH29W'
  }
};

export const primeEnv = {
  oauthUrl: 'https://sis.enemgameprime.com.br/oauth/token',
  // url dev
  apiUrl: 'https://sis.enemgameprime.com.br/api/v2',
  clientId: 1001,
  clientSecret: 'AYbPb5Dn7bFrsfDMHueRrT7PP2JTYMSbnTBqz5nw',
  grantType: 'client_credentials',
  apiId: '10155',
  studentProfile: 2,
  baseRedirect: 'https://sis.enemgameprime.com.br/sistema/rota/rotas_2/13189/xapi/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
