export class MatchToAnalytics {

    //Const
    ACTIONS = [
        "tool-eliminate",//"tool_eliminate",
        "tool-pass",//"tool_pass",
        "tool-answer-two", //tool-answer-two,
        "answer",//"answer",
        "quit",//"quit_match",
        "next-question",//"next_question",
        "watch-ad",//"watch_ad",
        "result",
        "click-ad",//"click_ad",
        "lost-connection",//"lost_connection",
        "return-after-disconnection",//"return_after_disconnection",
        "quit-after-disconnection"//"quit_after_disconnection"
    ];

    private startedAt;
    private endedAt;
    public questions;
    private actions;
    private result;
    private sadClicks;
    public ready;

    constructor() {
        this.startedAt = new Date();
        this.endedAt = null;
        this.questions = [];
        this.actions = [];
        this.result = null;
        this.sadClicks = [];
    }

    addAction(actionName) {
        if (!this.ACTIONS.includes(actionName)) {
            return console.error("Invalid action name");
        }
        let time = this.getTime();
        let question = this.getLastQuestion();
        this.actions.push({
            name: actionName,
            time,
            questionId: question.id,
            suitId: question.suitId
        });
    }

    addQuestion(question) {
        this.questions.push({
            id: question.id,
            startedAt: new Date(),
            suitId: question.suit.id,
            alternatives: question.alternatives.map(alternative => alternative.id)
        });
        this.ready = true;
    }

    addAnswer(alternativeId, alternativeIsCorrect) {
        let question = this.getLastQuestion();
        let time = this.getTime();
        question.answer = {
            alternativeId,
            alternativeIsCorrect,
            time,
            questionId: question.id,
            suitId: question.suitId
        };
    }

    addClickSad(userId, advertisementId) {
        let now = new Date();
        this.sadClicks.push({
            userId, advertisementId,
            createdAt: now,
            updatedAt: now
        });
    }

    end(result) {
        this.endedAt = new Date();
        this.result = result;
    }

    getTime() {
        return (new Date).getTime() - this.getLastQuestion().startedAt.getTime();
    }

    getLastQuestion() {
        let question = this.questions[this.questions.length - 1];
        if (!question) {
            return console.error("There is no question");
        }
        return question;
    }

    getAnalyticsInfo() {
        return {
            actions: this.actions,
            endedAt: this.endedAt,
            questions: this.questions,
            result: this.result,
            sadClicks: this.sadClicks,
            startedAt: this.startedAt
        }
    }

}
