import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from "@ionic/angular";
import { ServicesService } from './services.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class EconomyService {

  private storeUrl;
  private urlPurchase;
  public skipQuestionTool;
  public eliminateTool;
  public answerTwoTool;
  public continueMatch;

  constructor(public toastCtrl: ToastController, private user: UserService, private services: ServicesService, public http: HttpClient) {
    this.storeUrl = 'store/';
    this.urlPurchase = this.storeUrl + 'purchase/';
  }

  getStore() {
    this.services.get(this.storeUrl, Object.assign({
      success: res => {
        console.log("res store", res);
        res.data.forEach((item) => {
          if (item.name === "Pular pergunta") this.skipQuestionTool = item;
          else if (item.name === "Eliminar alternativas") this.eliminateTool = item;
          else if (item.name === "Marcas duas alternativas") this.answerTwoTool = item;
          else if (item.name === "Continuar partida") this.continueMatch = item;
        });
      },
      error: err => {
        console.error("Error on store get: ", err);
      }
    }));
  }

  getEconomyTool(tool) {
    switch (tool) {
      case "skipTool":
        return this.skipQuestionTool;
      case "answerTwoTool":
        return this.answerTwoTool;
      case "eliminateTool":
        return this.eliminateTool;
    }
  }

  purchase(item) {
    if (item) {
      this.user.updateMoney('spend', item.price);
      this.services.post(this.urlPurchase, Object.assign({
        data: { itemId: item.id },
        success: res => {
          console.log("Sucess on purchase post: ", res);
        },
        error: err => {
          console.error("Purchase Error: ", err);
          // todo quaqndo decidido o que utilizar para guardar dados localmente, guardar os dados locais para envio posterior
        }
      }));
    }
  }
}
