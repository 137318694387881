/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { Platform } from '@ionic/angular';
import { ServicesService } from './services.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**CONSTS*/
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public FB_API_VERSION;

  //USER INFO
  public userId;
  public _facebookId: boolean | number;
  public email;
  public username;
  public cpf;
  public emailHistory;
  // public facebook;
  public profile;
  public school;
  public premia;
  public isPremium;
  public hadPremium;
  public isStudent;
  public googleId;
  public googleplus;
  public firstPlay;
  public enemDolar;
  public level;
  public score;
  public singlePlayerRecord;
  public avatar;
  public coverImage;
  public facebookCoverImage;
  public status;
  // Prime Info
  public alunoId: number;

  // FirebaseX Token
  public firebaseTempToken;

  public levels = {
    remainingToNextLevel: 0,
    differenceBetweenLevels: 0,
    progressInLevel: 0
  };

  public facebookFriendsInfo: any[] = [];

  public matches;
  public matchReviewNotReady;
  public opponents;
  public prime_email;

  constructor(
    private fb: Facebook,
    public http: HttpClient, private platform: Platform, private services: ServicesService,
    private util: UtilService) {/*  this.FB_API_VERSION = 'v6.0';  */ }

  //GETTERS AND SETTERS

  public get facebookId(): boolean | number {
    return this._facebookId;
  }
  public set facebookId(value: boolean | number) {
    this._facebookId = value;
  }
  clearService() {
  this.userId = this.facebookId = this.email = this.username = this.cpf = this.emailHistory = this.profile = this.school = this.prime_email
     = this.premia = this.isPremium = this.hadPremium = this.isStudent = this.googleId = this.googleplus = this.firstPlay = this.enemDolar =
      this.level = this.score = this.singlePlayerRecord = this.avatar = this.matchReviewNotReady = this.coverImage = this.facebookId = null;
    this.levels = {
      remainingToNextLevel: 0,
      differenceBetweenLevels: 0,
      progressInLevel: 0
    };
    this.facebookFriendsInfo = this.matches = this.opponents = [];
  }

  getId() {
    return this.userId;
  }

  setScore(score) {
    this.score += score;
  }

  //METHODS

  setInfo(user) {
    this.userId = user && user.id;
    this.facebookId = user && user.facebookId || false;
    this.email = user && user.email;
    this.username = user && user.username;
    this.cpf = user && user.cpf;
    this.emailHistory = user && user.emailHistory;
    this.facebookId = user && user.facebookId;
    this.profile = user && user.profile;
    this.school = user && user.school;
    this.premia = user && user.premia;
    this.isPremium = user && user.isPremium;
    this.hadPremium = user && user.hadPremium;
    this.isStudent = user && user.isStudent;
    this.googleId = user && user.googleId;
    this.googleplus = user && user.googleplus;
    this.firstPlay = user && user.firstPlay;
    this.singlePlayerRecord = user && user.singlePlayerRecord;
    this.score = user && user.score;
    this.alunoId = user && user.aluno_id;
    this.status = user && user.status;
    this.prime_email = user && user.prime_email;
    this.calculateLevel();
    if (user.profile) {
      this.avatar = this.util.getAvatar(user.profile.avatar, user.facebookId);
      if (this.util.firebase) {
        this.setFirebaseUserProperties();
      }
    }
    this.enemDolar = user && user.credits;
    this.updateFirebaseToken(user.firebaseToken);
    if (user.facebookId) {
      console.log('user.friends: ', user.friends);
      if (user.friends.length > 0) { this.populateFacebookFriendsInfo(user.friends); }
      // this.setFacebookCoverImage(user.profile.coverImage, user);
    } else {
      // if have profile, user log in with username
      // if does not have profile, user log in with google
      console.log(user.profile);
      this.coverImage = user.profile
      ? this.util.getCover(user.profile.coverImage, user.facebookId)
      : user.photoURL; }
  }

  setFirebaseUserProperties() {
    this.util.firebase.setUserId(this.userId);
    this.util.firebase.setUserProperty('user_city', this.profile.city);
    this.util.firebase.setUserProperty('user_state', this.profile.state);
    this.util.firebase.setUserProperty('user_school', this.school);
  }

  updateFirebaseToken(token) {
    console.log('update firebase token: ', token);
    if (this.platform.is('mobile')) {
      if (this.userId && (!token || token !== this.firebaseTempToken)) {
        this.services.post('users/' + this.userId + '/firebase', {
          data: { token: this.firebaseTempToken },
          success: res => console.log('Sucesso ao atualizar token do firebase: '),
          error: err => console.error('Erro ao atualizar token do firebase: ', err)
        });
      }
    }
  }

  updateMoney(operation, quantity) {
    switch (operation) {
      case 'spend':
        this.enemDolar -= quantity;
        break;
      case 'gain':
        this.enemDolar += Math.round(quantity);
    }
  }

  updateSinglePlayerRecord(questionNum) {
    this.singlePlayerRecord = questionNum;
  }

  public getAvatar() {
    return this.util.getAvatar(this.avatar, this.facebookId);
  }

  public setAvatar(avatar) {
    this.avatar = this.util.getAvatar(avatar, this.facebookId);
    this.profile.avatar = avatar;
  }

  public setCover(cover) {
    if (cover.id === 'facebook') { this.coverImage = this.facebookCoverImage; }
    else { this.coverImage = this.util.getCover(cover.id, this.facebookId); }
    this.profile.coverImage = cover.id;
  }

  setFacebookCoverImage(coverImage, user) {
    this.fb.getLoginStatus().then(res => {
      this.fb.api('/' +
        this.FB_API_VERSION +
        '/' + this.facebookId +
        '/?fields=cover', ['email', 'public_profile', 'user_friends'])
        .then(data => {
          console.log('data: ', data);
          if (data.cover) { this.facebookCoverImage = data.cover.source; }
          if (coverImage === 'facebook' || !coverImage) {
            this.coverImage = this.facebookCoverImage;
          } else {
            this.coverImage = this.util.getCover(user.profile.coverImage, user.facebookId);
          }
          this.util.getCovers(this);
        }).catch(err => {
          console.error('Error on facebook cover get: ', err);
          this.coverImage = this.util.getCover(user.profile.coverImage, user.facebookId);
        });
    }).catch(err => {
      console.error('error on login status: ', err);
      this.coverImage = this.util.getCover(user.profile.coverImage, user.facebookId);
    });
  }

  getUserInfo() {
    return {
      id: this.userId,
      enemDolar: this.enemDolar,
      username: this.username,
      profile: this.profile,
      school: this.school,
      level: this.level,
      facebookId: this.facebookId,
      score: this.score,
      singlePlayerRecord: this.singlePlayerRecord,
      email: this.email,
      coverImage: this.coverImage,
      facebookCoverImage: this.facebookCoverImage,
      googleId: this.googleId
    };
  }

  populateFacebookFriendsInfo(friends) {
    friends.forEach((friend) => {
      this.services.get('auth/whois', {
        data: { facebookId: friend.facebookId },
        success: res => {
          this.facebookFriendsInfo.push(res.data);
        },
        error: e => console.error('Error on whois', e)
      });
    });
  }

  //    todo DEV METHODS (retirar na versão prod)
  whoAmIForAll() {
    this.userId = 33665;
    this.email = 'saria@zelda.com';
    this.username = 'saria';
    this.profile = {
      avatar: '../../assets/imgs/dev/saria.png',
      city: 'Fortaleza',
      firstName: 'Saria',
      gender: 'f',
      lastName: 'Kokiri',
      state: 'CE',
      telephoneNumber: '8588457854',
      intendedCourses: ['Jogos Digitais', 'Psicologia'],
      grade: '3'
    };
    this.school = { name: 'Kakariko' };
    this.level = 10;
    this.score = 2350;
    this.singlePlayerRecord = 2;
    this.avatar = '../../assets/imgs/dev/saria.png';
    this.enemDolar = 1787;
    this.status = 1;
  }

  getAvatarDev() {
    return '../../assets/imgs/dev/saria.png';
  }

  calculateLevel() {
    this.level = Math.floor((Math.sqrt(55 ** 2 + 20 * Number(this.score)) - 55) / 10 + 1);
    return this.level;
  }

  calculateLevelXp(level) {
    return 5 * ((level - 1) ** 2 + 11 * (level - 1));
  }

  calculateLevels() {
    this.calculateLevel();
    const actualLevel = this.calculateLevelXp(this.level);
    this.levels.differenceBetweenLevels = this.calculateLevelXp(this.level + 1) - actualLevel;
    this.levels.progressInLevel = this.score - actualLevel;
    this.levels.remainingToNextLevel = this.levels.differenceBetweenLevels - this.levels.progressInLevel;
  }

  public getPlayerHistories() {
    this.services.get('player-histories', {
      success: res => {
        const { matches } = res.data;
        this.matches = matches;
        this.opponents = res.data.opponents;
        this.matchReviewNotReady = null;
      },
      error: err => {
        console.error('Error on historic get', err);
        this.matchReviewNotReady = true;
      }
    });
  }
}
