import { Component } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { BugReportPopoverPage } from '../../modals/bug-report-popover/bug-report-popover';
// import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { ServicesService } from 'src/app/services/services.service';
import { UserService } from 'src/app/services/user.service';
import { ClearServicesService } from 'src/app/services/clear-services.service';
import { AudioService } from 'src/app/services/audio.service';

@Component({
    selector: 'game-menu',
    templateUrl: 'game-menu.component.html',
    styleUrls: ['game-menu.component.scss']
})
export class GameMenuComponent {
    public settingsOpen = false;
    pages: Array<{ title: string; component: any }>;

    constructor(
        private navCtrl: NavController,
        private services: ServicesService,
        public popoverCtrl: PopoverController,
        // private fb: Facebook,
        public user: UserService,
        private clearServices: ClearServicesService,
        public audio: AudioService,
        ) {
        // used for an example of ngFor and navigation
        this.pages = [];
    }

    openPage(page) {
        // Reset the content nav to have just this page
        // we wouldn't want the back button to show in this scenario
        this.navCtrl.navigateRoot(page);
    }

    slideLeft() {
        const SettingsMenu = document.getElementById('menu-container');
        SettingsMenu.style.webkitTransform = 'translate3d(0,0,0)';
        this.settingsOpen = !this.settingsOpen;
    }

    slideRight() {
        const SettingsMenu = document.getElementById('menu-container');
        SettingsMenu.style.webkitTransform = 'translate3d(-100vw,0,0)';
        this.settingsOpen = !this.settingsOpen;
    }

    disconnect() {
        const self = this;
        const success = res => {
            // this.fb.getLoginStatus().then(res => {
            //     if (res.status === "connected") this.fb.logout()
            //      .then(res => console.info("Successfully disconnected from Facebook: ", res))
            //      .catch(err => console.error("Error disconnecting from Facebook: ", err));
            // });
            self.navCtrl.navigateRoot('/welcome').then(() => {
                this.clearServices.clear();
            });

        };
        this.services.logout({ success });
    }

    async bugReport() {
        const popover = await this.popoverCtrl.create({
            component: BugReportPopoverPage,
            cssClass: 'pop-default bug-report',
            showBackdrop: true,
            backdropDismiss: true
        });
        await popover.present();
        this.audio.play(this.audio.SFX_ID.POPOVER_PRESENT);
    }
}
