import { Component } from '@angular/core';
import { NavController, NavParams, PopoverController, ToastController } from '@ionic/angular';
import { ServicesService } from 'src/app/services/services.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'page-avatars-covers',
  templateUrl: 'avatars-covers.html',
  styleUrls: ['avatars-covers.scss']
})
export class AvatarsCoversPage {

  constructor(
    private popoverCtrl: PopoverController,
    public navCtrl: NavController,
    public navParams: NavParams,
    public util: UtilService,
    public services: ServicesService,
    private user: UserService,
    public toastCtrl: ToastController) {
  }


  selectAvatar(avatar) {
    console.log(this.util.covers);
    console.log(this.util.avatars);
    const selectedAvatar = this.util.avatars.find(_avatar => _avatar.selected === true);
    if (selectedAvatar === avatar) { return; } else {
      avatar.selected = !avatar.selected;
      selectedAvatar.selected = !selectedAvatar.selected;
    }
  }

  selectCover(cover) {
    const selectedCover = this.util.covers.find(_cover => _cover.selected === true);
    if (selectedCover === cover) { return; } else {
      cover.selected = !cover.selected;
      selectedCover.selected = !selectedCover.selected;
    }
  }

  async closeModal() {
    const popover = await this.popoverCtrl.getTop();

    if (popover) {
      await popover.dismiss(null);
    }
  }

  async changeAvatar() {
    const selectedAvatar = this.util.avatars.find(avatar => avatar.selected === true);
    const popover = await this.popoverCtrl.getTop();
    if (this.user.profile.avatar === selectedAvatar.image) {
      if (popover) {
        await popover.dismiss(null);
      }
    } else {
      this.services.put('profiles/' + this.user.getId(), {
        data: { avatar: selectedAvatar.image },
        success: async res => {
          this.user.setAvatar(selectedAvatar.image);
          if (popover) {
            await popover.dismiss(null);
          }
        },
        error: async err => {
          const toast = await this.toastCtrl.create({
            message: 'Não foi possível alterar seu avatar.',
            duration: 3000
          });
          await toast.present();

          if (popover) {
            await popover.dismiss(null);
          }
        }
      });
    }
  }
  async changeCover() {
    const popover = await this.popoverCtrl.getTop();
    const selectedCover = this.util.covers.find(cover => cover.selected === true);
    if (this.user.profile.cover === selectedCover.id) {
      if (popover) {
        await popover.dismiss(null);
      }
    } else {
      this.services.put('profiles/' + this.user.getId(), {
        data: { coverImage: selectedCover.id },
        success: async res => {
          console.log('Sucesso ao alterar cover image: ', res);
          this.user.setCover(selectedCover);
          if (popover) {
            await popover.dismiss(null);
          }
        },
        error: async err => {
          console.error('Erro ao tentar alterar cover image: ', err);
          const toast = await this.toastCtrl.create({
            message: 'Não foi possível alterar sua foto de capa.',
            duration: 3000
          });
          await toast.present();
          if (popover) {
            await popover.dismiss(null);
          }
        }
      });
    }
  }

}
